import React, { useEffect, useMemo, useState } from "react";
import { HeaderWrapper } from "./header.form.style";
import logo from "../../assets/images/logo_light.png";
import Modal from "react-modal";
import { openModal } from "../../functions/functions";
import {
  FormControl,
  MenuItem,
  Button,
  InputLabel,
  Box,
  Select,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import LZString from "lz-string";

const modalStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "350px",
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    transform: "translate(-50%, -50%)",
    
  },
  overlay: { zIndex: 1000 },
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const id = urlParams.get("id");

const HeaderForm = ({
  handleThemeChange,
  stopRecording,
  handleChangeLang,
  setIsQuestionnaireFinished,
}) => {
  const [apiData, setApiData] = useState(null);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState();
  const [hideAppointment, setHideA] = useState("none");
  const [hideOther, setHideO] = useState("none");
  const [hideComment, setHideC] = useState("none");
  const [value, setValue] = React.useState(null);
  const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");
  let object = {};

  function handleChange(e) {
    setStatus(e.target.value);
    let text = [];
    for (let i = 0; i < apiData.data.statusList.statuses.length; ++i) {
      if (apiData.data.statusList.statuses[i].text == e.target.value) {
        text = apiData.data.statusList.statuses[i];
      }
    }

    if (text.appointment == true) {
      setHideA("block");
    } else {
      setHideA("none");
    }
    if (text.other == true) {
      setHideO("block");
    } else {
      setHideO("none");
    }
    if (text.comments == true) {
      setHideC("block");
    } else {
      setHideC("none");
    }
  }

  async function storeSatus() {
    
    setIsQuestionnaireFinished(true);
    setSubmitText("Submitting...");
    await stopRecording();
    setTimeout(() => {
      let statusData =
        document.forms["status-form"].getElementsByTagName("input");
      let select = document.getElementById("select-status");

      object["statusId"] = select.options[select.selectedIndex].id;
      for (let i = 0; i < statusData.length; ++i) {
        if (statusData[i].value != "") {
          if (statusData[i].id === "") {
            object["date"] = statusData[i].value;
          } else {
            object[statusData[i].id] = statusData[i].value;
          }
        }
      }
     
      let session = localStorage.getItem("session");
      let currentSession = localStorage.getItem("currentSession");
      let sessionObject = JSON.parse(localStorage.getItem("Object" + session));
      
      if(sessionObject === null) {
        if (process.env.NODE_ENV === "development") {
          window.location.href = "/projects";
        } else {
          window.location.href = "/offline_quiz/projects";
        }
        return;
      }
      sessionObject["status"] = object;
      localStorage.setItem("Object" + session, JSON.stringify(sessionObject));
      session = parseInt(session);
      session += 1;
      
      localStorage.setItem("session", session);
      localStorage.setItem("currentSession", Number(currentSession) + 1);
      if (process.env.NODE_ENV === "development") {
        window.location.href = "/projects";
      } else {
        window.location.href = "/offline_quiz/projects";
      }
    }, 1500);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const checkLang = useMemo(() => {
    if (!apiData) {
      return "none";
    }

    if (apiData?.data?.translations?.length == 0) {
      return "none";
    }
    return "block";
  }, [apiData]);

  useEffect(() => {
    if (id) {
      let localStorageApiData = localStorage.getItem("apiData" + id);

      if (localStorageApiData) {
        localStorageApiData = LZString.decompress(localStorageApiData);
        setApiData(JSON.parse(localStorageApiData));
      }
    } else {
      // TODO: schimba ecranul, il duci inapoi, afisezi eroare
    }
  }, []);

  if (!apiData) {
    // TODO un loading mai frumos
    return <div></div>;
  }

  return (
    <HeaderWrapper className="HeaderWrapper">
      <img src={logo} alt="" style={{marginLeft:'-14px'}}/>
      <Box className="effect6 change-theme" onClick={handleThemeChange} style={{marginLeft:1, paddingRight:'10px'}}>
        Change Theme
      </Box>
      <div
        className="effect6 status-button"
        id="status-button"
        onClick={() => {
          setModal(openModal(true));
        }}
        style={{  marginLeft: "1%"}}
      >
        Status
      </div>
      <Box sx={{ display: checkLang }} style={{paddingLeft:'10px'}}>
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            id="demo-simple-select-label"
            sx={{ marginLeft: "-10px", marginTop: "-8px", fontSize: "15px", color :"white"}}
          >
            Language
          </InputLabel>
          <Select
            sx={{
              height: "40px",
              marginLeft: "-10px",
              width: "100%",
              color: "white",
              zIndex: "100",
              fontSize: "15px",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Language"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={handleChangeLang}
          >
            <MenuItem value={0}>Implicit</MenuItem>
            {apiData.data.languages.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Modal isOpen={modal} style={modalStyles} >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row-reverse",marginTop: '-20px',
    marginRight: '-11px' }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setModal(openModal(false));
            }}
            size="small"
            style={{ marginBottom: "10px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <div>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-standard-label"
              sx={{ marginLeft: "0px" }}
            ></InputLabel>
            <select
              onChange={handleChange}
              name="dada"
              label="Select"
              style={{ width: "100%", padding: "10px", marginTop: "10px" }}
              id="select-status"
            >
              {apiData.data.statusList.statuses.map((answerOption, index) => {
                return (
                  <option
                    key={index}
                    value={answerOption.text}
                    id={answerOption.id}
                  >
                    {answerOption.text}
                  </option>
                );
              })}
            </select>
          </FormControl>
        </div>
        <form name="status-form">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ display: hideAppointment }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} id="date">
                <DatePicker
                  id="date"
                  label="Basic example"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              id="phone"
              label="Phone"
              variant="standard"
              fullWidth
              sx={{ display: hideAppointment }}
            />
            <TextField
              id="comment"
              label="Comment"
              variant="standard"
              fullWidth
              sx={{ display: hideComment }}
            />
            <TextField
              id="other"
              label="Other"
              variant="standard"
              fullWidth
              sx={{ display: hideOther }}
            />
          </Box>
        </form>
        <Button
          variant="contained"
          sx={{ marginTop: "20px", float:'right' }}
          disabled={disable}
          onClick={() => {
            storeSatus();
          }}
        >
          {submitText}
        </Button>
      </Modal>
    </HeaderWrapper>
  );
};

export default HeaderForm;
