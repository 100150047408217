import React, { useState, useEffect } from "react";
import NavBar from "../../componets/navBar/navBar.component";
import { DashboardWrapper } from "../dashboard/dashboard.style";
import Header from "../../componets/header/header.component";
import { showNavBar } from "../../functions/functions";
import {
  Container,
  Button,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import LinkIcon from "@mui/icons-material/Link";
import {
  GreenTextTypography,
  RedTextTypography,
} from "../projects/project.style";
import { API_URL_PROJECT_ID, API_URL_RECORDING } from "../../constants";
import axios from "axios";
import Modal from "react-modal";
import LZString from "lz-string";
import * as Sentry from "@sentry/react";

const modalStyles = {
  content: {
    top: "40%",
    left: "52%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "60%",
    border: "none",
    backgroundColor: "transparent",
    transform: "translate(-50%, -50%)",
  },
};

const modalStyles2 = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-20%",
    width: "60%",
    border: "none",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
  },
};

var sentQuestionnires = 0;
var dontShowMessage =false;
var soundsObject = JSON.parse(localStorage.getItem("unsentSounds"));
var error = false;
async function sendData() {
  let data;
  let session = localStorage.getItem("currentSession");
  for (let i = 1; i <= session; ++i) {
    data = localStorage.getItem("Object" + i);
    data = JSON.parse(data);
    if (data != null && data.status != "") {
      axios(API_URL_PROJECT_ID + data.questionnaireId, {
        method: "POST",
        withCredentials: true,
        data: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          ++sentQuestionnires;
          if (sentQuestionnires == 1) {
            document.getElementById("sentQuestionnaires").innerHTML =
              sentQuestionnires + " Questionnaire is being sent";
          } else {
            document.getElementById("sentQuestionnaires").innerHTML =
              sentQuestionnires + " Questionnaires are currently being sent";
          }
          
          let request = indexedDB.open("recordings"),
             db = null,
            createObjectStore = (dataBase) => {
              // Create an objectStore

              dataBase.createObjectStore("sounds");
            };

          request.onupgradeneeded = function (event) {
            createObjectStore(event.target.result);
          };

          request.onerror = function (event) {
            console.log('You dont have access to indexedDb')
            localStorage.removeItem("Object" + i);
          };

          request.onsuccess = async function (event) {

            db = request.result;

            // Try sending the data
            db.onerror = function (event) {};

            const transaction = db.transaction(["sounds"], "readwrite");
            const soundsStore = transaction.objectStore("sounds");
            const getAllRequest = soundsStore.get("sound" + Number(i + 1));

            let index = 0;
            getAllRequest.onsuccess = (eventSuccess) => {
              let blob = eventSuccess.target.result;
              try {
                blob = new Blob(blob);
                blob = blob.slice(0, blob.size, "mp3");
              } catch (error) {
                Sentry.captureMessage(error);
                blob = null;
              }

              if (blob) {
                
                var data = new FormData();
                let myFile;
                let errorFlag = false;
                try {
                  myFile = new File([blob], "recording" + index);
                  index++;
                 

                  data.append("recording", myFile, myFile.name);
                  data.append("id", response.data.id);
                } catch(error) {
                  Sentry.captureMessage(error);
                  errorFlag =true;
                }

                if (response.data.id != "" && errorFlag == false) {
                  axios(API_URL_RECORDING, {
                    method: "POST",
                    withCredentials: true,
                    data: data,
                    headers: {
                      "Content-type": "multipart/form-data; boundary=something",
                    },
                    redirect: "follow",
                  })
                    .then((response) => {
                      console.log("SENDING AUDIO RECORDING");
                    })
                    .then(() => {
                      console.log("Deleting Sound");
                      localStorage.removeItem("Object" + i);
                      deleteSound(i);
                    })
                    .catch((err) => {
                      document.getElementById("sentQuestionnaires").innerHTML =
                        " There has been an error sending the audio recording try relogging into the account";
                      var da = soundsObject;
                      da["sound" + (i + 1)] = [response.data.id];

                      localStorage.setItem("unsentSounds", JSON.stringify(da));
                      localStorage.removeItem("Object" + i);
                      console.log(err);
                    });
                } else {
                  console.log("Questionnaire ID was not provided");
                }
              } else {
                localStorage.removeItem("Object" + i);
              }
            };
          };
        })
        .catch((err) => {
          document.getElementById("sentQuestionnaires").innerHTML =
            "Your request token may have been used on another device or may have expired, relog into your account to get a new one.";
          error = true;
          dontShowMessage = true;
        });
    }
  }
}

function sendUnsentSounds() {
  for (let i = 0; i < Object.keys(soundsObject).length; ++i) {
    let request = indexedDB.open("recordings"),
      db,
      createObjectStore = (dataBase) => {
        // Create an objectStore

        dataBase.createObjectStore("sounds");
      };

    request.onupgradeneeded = function (event) {
      createObjectStore(event.target.result);
    };

    request.onerror = function (event) {};

    request.onsuccess = async function (event) {
      db = request.result;

      // Try sending the data
      db.onerror = function (event) {};

      const transaction = db.transaction(["sounds"], "readwrite");
      const soundsStore = transaction.objectStore("sounds");
      const getAllRequest = soundsStore.get(Object.keys(soundsObject)[i]);
      let index = 0;

      getAllRequest.onsuccess = (eventSuccess) => {
        const blob = eventSuccess.target.result;

        if (blob) {
          const myFile = new File([blob], "recording" + index);
          index++;
          var data = new FormData();
          data.append("recording", myFile, myFile.name);
          data.append("id", soundsObject[Object.keys(soundsObject)[i]]);
          return axios(API_URL_RECORDING, {
            method: "POST",
            withCredentials: true,
            data: data,
            headers: {
              "Content-type": "multipart/form-data; boundary=something",
            },
            redirect: "follow",
          })
            .then((response) => {
              console.log("SENDING AUDIO RECORDING");
            })
            .then(() => {
              console.log("Deleting Sound");
              
              delete soundsObject[Object.keys(soundsObject)[i]];
              localStorage.setItem(
                "unsentSounds",
                JSON.stringify(soundsObject)
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("Questionnaire ID was not provided");
        }
      };
    };
  }
}

async function deleteSound(i) {
  
  return new Promise((resolve) => {
    setTimeout(() => {
      let session = localStorage.getItem("session");
      let request = indexedDB.open("recordings"),
        db,
        createObjectStore = (dataBase) => {
          // Create an objectStore

          dataBase.createObjectStore("sounds");
        };

      request.onupgradeneeded = function (event) {
        createObjectStore(event.target.result);
      };

      request.onerror = function (event) {};

      request.onsuccess = function (event) {
        db = request.result;

        // Try sending the data
        db.onerror = function (event) {};

        const transaction = db.transaction(["sounds"], "readwrite");
        const soundsStore = transaction.objectStore("sounds");
        const getAllRequest = soundsStore.getAll();
        soundsStore.delete("sound" + Number(i + 1));
        localStorage.removeItem("Object" + i);
      };
    });
  });
}
// sendData();
sendUnsentSounds();
const Projects = ({ project }) => {
  localStorage.setItem('timers',JSON.stringify([]))
  const [openNavBar, setNavBar] = useState(false);
  localStorage.setItem("language","0")
  // const [apiInfo, setApiInfo] = useState("");
  const [disable, setDisable] = useState(false);
  const [modal, openModal] = useState(false);
  const [modal2, openModal2] = useState(false);
  const [session, setSession] = useState();
  const [questionnaires, setQuestionnaires] = useState(
    JSON.parse(localStorage.getItem("questionnaires"))
  );
  const [accessId, setAccessId] = useState();
  const [removableObject, setRemovableObject] = useState();
  const [questionnairesToBeSent, setquestionnairesToBeSent] = useState();
  const [sendingQuestionnairesText, setsendingQuestionnairesText] = useState();
  const [disableAllButons, setdisableAllButons] = useState();
  const [dis,setDis] = useState(true);
  const [disabledButton, setDisableButton] = useState(!window.navigator.onLine);
  if (localStorage.getItem("session") == null) {
    localStorage.setItem("session", 1);
  }
  function redirectToForm(id) {
    checkMicrophonePermission(id)
    
  }

  function checkConnection() {
    setInterval(() => {
      if (window.navigator.onLine && availableResync() == true) {
        // setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }, 8000);
  }
  useEffect(() => {
    setSession(localStorage.getItem("currentSession"));
    let session = localStorage.getItem("currentSession");
    if (JSON.parse(localStorage.getItem("Object" + Number(session - 1)))) {
      let finalObject = JSON.parse(
        localStorage.getItem("Object" + Number(session - 1))
      );
      if (
        localStorage.getItem("timers") != null &&
        JSON.parse(localStorage.getItem("dates")) != null
      ) {
        finalObject.timers = localStorage.getItem("timers");
        finalObject.dates = JSON.parse(localStorage.getItem("dates"));
      }
      localStorage.setItem(
        "Object" + Number(session - 1),
        JSON.stringify(finalObject)
      );
    }
    getSendableQuestionnaires();
    checkConnection();
  }, []);

  function availableResync() {
    let questionnaires;
    let countQuestionnaires = 0;
    let session = localStorage.getItem("currentSession");
    for (let i = 1; i <= session; ++i) {
      questionnaires = localStorage.getItem("Object" + i);
      questionnaires = JSON.parse(questionnaires);
      if (questionnaires != null && questionnaires.status != "" && questionnaires.questions.length != 0) {
        ++countQuestionnaires;
      }

      //if in the localstorage object the questions array is empty delete the object
      if (questionnaires != null && questionnaires.questions.length == 0) {
        localStorage.removeItem("Object" + i);
      }
    }

    if (countQuestionnaires == 0) {
      return false;
    } else {
      return true;
    }
  }
  function getSendableQuestionnaires() {
    if (error == true) {
      return;
    }
    let questionnaires;
    let countQuestionnaires = 0;
    let session = localStorage.getItem("currentSession");
    for (let i = 1; i <= session; ++i) {
      questionnaires = localStorage.getItem("Object" + i);
      questionnaires = JSON.parse(questionnaires);
      
      if (questionnaires != null && questionnaires?.status != "" && questionnaires?.status != undefined) {
        ++countQuestionnaires;
      }
    }

    setquestionnairesToBeSent(countQuestionnaires);

    if (countQuestionnaires == 0) {
      setsendingQuestionnairesText("");
      setDisableButton(true);
      document.getElementById("sentQuestionnaires").innerHTML = "";
    } else {
      setInterval(() => {
        getSendableQuestionnaires();
      }, 2500);
    }
  }

  function sendQuestionnaires() {
    setTimeout(() => {
      try {
        sendData().then(() => {
          getSendableQuestionnaires();

          if (questionnairesToBeSent != 0 && dontShowMessage == false) {
            setsendingQuestionnairesText(
              "Do not change pages until this message dissapears."
            );
          } else {
            setsendingQuestionnairesText("");
          }
        });
      } catch {
        setsendingQuestionnairesText(
          "There has been an error sending the questionnaires."
        );
      }
    }, 2000);
  }

  // function getLocalStream(id) {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: false, audio: true })
  //     .then((stream) => {
        
  //     })
  //     .catch((err) => {
  //       openModal(true);
  //       setTimeout(() => {
  //         openModal(false);
  //       }, 2500);
  //     });
  // }

  function checkMicrophonePermission(id) {
    navigator.permissions.query({ name: 'microphone' }).then(permissionStatus => {
      if (permissionStatus.state === 'granted') {
        
        getLocation(id);
      } else if (permissionStatus.state === 'prompt') {
        navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
          getLocation(id);
        }).catch((err) => {
          console.log(err)
        });
        } else {
        openModal(true);
        setTimeout(() => {
          openModal(false);
        }, 2500);
      }
    });
  }

  function getLocation(id) {
    var found = 0;
    var locationIsOpened = false;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);

      for (let i = 1; i <= session; ++i) {
        var object = JSON.parse(localStorage.getItem("Object" + i));

        if (object != null && object.status === "") {
          if (object.questionnaireId == id) {
            setAccessId(id);
            setRemovableObject("Object" + i);
            ++found;
            openModal2(true);
            break;
          }
        }
      }

    }
    function showPosition(position) {
      locationIsOpened = true;
      setDis(false)
      localStorage.setItem('coordonates',
      JSON.stringify({ 
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }))
      if (found == 0 && locationIsOpened == true) {

        if (process.env.NODE_ENV === "development") {
          window.location.href = "/form?id=" + id;
        } else {
          window.location.href = "/offline_quiz/form?id=" + id;
        }
      }
    }

    function showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          openModal(true);
          setTimeout(() => {
            openModal(false);
          }, 2500);
          break;
        case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          console.log("An unknown error occurred.");
          break;
      }
    }
  }
  
  function handleCloseNavBar(){
    setNavBar(false);
  }

  function resyncData() {
    localStorage.clear();
    if (process.env.NODE_ENV === "development") {
      window.location.href = "/";
    } else {
      window.location.href = "/offline_quiz";
    }
  }

  function accessQuestionnaire(access) {
    if (access == true) {
      if (process.env.NODE_ENV === "development") {
        window.location.href = "/form?id=" + accessId;
      } else {
        window.location.href = "/offline_quiz/form?id=" + accessId;
      }
    } else {
      localStorage.removeItem(removableObject);
      if (process.env.NODE_ENV === "development") {
        window.location.href = "/form?id=" + accessId;
      } else {
        window.location.href = "/offline_quiz/form?id=" + accessId;
      }
    }
  }

  return (
    <DashboardWrapper className="DashboardWrapper">
      <Modal isOpen={modal} style={modalStyles}>
        You have to give acces to location and microfon to start.
      </Modal>

      <Modal isOpen={modal2} style={modalStyles2}>
        <div style={{textAlign:'center'}}>
          This questionnaire is not finished, would u like to start a new one or
          continue the current one.{" "}
        </div>
        <button
          onClick={() => {
            accessQuestionnaire(false);
          }}
          class="button3"
          disabled={dis}
          style={dis == true ? {backgroundColor:"grey"}: {backgroundColor:"#37474f"}}
        >
          Start new one
        </button>
        <button
          onClick={() => {
            accessQuestionnaire(true);
          }}
          class="button3"
          disabled={dis}
          style={dis == true ? {backgroundColor:"grey"}: {backgroundColor:"#37474f"}}
        >
          Continue
        </button>
      </Modal>
      <Header NavBarSwitch={() => {setNavBar(showNavBar(openNavBar))}} />
      <Container>
        {/* <Button
          variant="contained"
          disabled={availableResync()}
          onClick={() => {
            resyncData();
          }}
        >
          Resync LocalStorage
        </Button> */}
        <Typography variant="h5" sx={{ display: "inline" }}>
          Projects version 6.9.5
        </Typography>
        <LinkIcon
          sx={{ fontSize: 40, marginLeft: "20px", marginTop: "-5px" }}
        ></LinkIcon>
        <br></br>
        <Button
          disabled={disabledButton}
          sx={{ marginTop: "1%" }}
          onClick={() => {
            setDisableButton(true)
            sendQuestionnaires();
          }}
          variant="contained"
        >
          Upload answers to server
        </Button>
        <Typography>
          You have a total of{" "}
          <span style={{ color: "blue" }}>{questionnairesToBeSent}</span> unsent
          questionnaires from this device.
          <br></br>
          <div id="sentQuestionnaires"></div>
          {sendingQuestionnairesText}
        </Typography>

        <Container>
          {questionnaires.map((questionnaire) => (
            <Divider
              sx={{ marginTop: "30px" }}
              className="media"
              // onClick={() => {
              //   redirectToForm(questionnaire.id);
              // }}
            >
              <PlayCircleOutlineIcon
                sx={{ marginLeft: -2, pointerEvents: disableAllButons }}
                onClick={() => {
                  redirectToForm(questionnaire.id);
                }}
              ></PlayCircleOutlineIcon>
              <Divider className="media-body align-self-center mr-3">
                <Typography variant="h6">{questionnaire.name}</Typography>
                <Box
                  className="text-muted"
                  component="span"
                  sx={{ display: "inline" }}
                >
                  100/
                  <GreenTextTypography>
                    {questionnaire.status}
                  </GreenTextTypography>
                  /
                  <RedTextTypography>
                    {100 - questionnaire.status}
                  </RedTextTypography>
                </Box>
              </Divider>
            </Divider>
          ))}
        </Container>
      </Container>

      <NavBar showNavBarContent={openNavBar} closeNavBarContent={handleCloseNavBar} />
    </DashboardWrapper>
  );
};

export default Projects;
