/**
 * This is the component that handles expression evaluation after parsing
 */

/**
 * Transforms and evaluates a question and the function asociated with it
 * @param {*} questionInfo Any information about the question
 * @param {*} func The function to be search for
 * @returns wether the evaluation is successful or not
 */
import LZString from "lz-string";
import * as Sentry from "@sentry/react";

export const removeExpressionLefovers = (myString) => {
  // Remove frontal !

  myString = myString.startsWith("!") ? myString.substring(1) : myString;

  // Remove trailing front parantheses from string
  while (
    myString.lastIndexOf("(") !== myString.indexOf("(") &&
    myString.lastIndexOf("(") !== -1
  ) {
    myString = myString.substring(myString.indexOf("(") + 1);
  }
  myString = myString.startsWith("!") ? myString.substring(1) : myString;
  // Remove trailing back parantheses from string
  const indexOfLastBracket = myString.indexOf(")");
  const end =
    indexOfLastBracket === -1 ? myString.length : indexOfLastBracket + 1;

  return myString.substring(0, end);
};

const verifyCondition = (element, finalObject) => {
  const [elementName, elementFunction] = element.split(".");

  const previousQuestionInfo = finalObject.questions.find(
    (q) => q.questionName === elementName
  );

  return transformAndEvaluate(previousQuestionInfo, elementFunction);
};
// "a46.ovalue(1)==a46.ovalue(2) && !a46.oeq(1,9)"

const insertSpaces = (expression) => {
  const regex = /\s*(\+|-|\*|<=|>=|!=|\|\||&&|<|>|\+)\s*/gm;
  const str = expression;
  const subst = ` $1 `;
  var result = "";
  // The substituted value will be contained in the result variable
  if (str != null) {
    result = str.replace(regex, subst);
  }
  return result;
};

export const evaluateExpression = (
  expression1,
  finalObject,
  evalFlag = true
) => {
  var expression = insertSpaces(expression1);
  // Gets the elements of the expresion
  if (expression) {
    var v1 = expression.split(" ");

    var v2 = v1.map(removeExpressionLefovers);

    const elements = v2
      .reduce((acc, element) => {
        // Remove && and || from expresion elements
        if (
          element !== "&&" &&
          element !== "||" &&
          element !== "+" &&
          element !== "-" &&
          element !== "*" &&
          element !== "true" &&
          element !== "false" &&
          element !== "==" &&
          element !== "!=" &&
          element !== "!" &&
          element !== "=" &&
          element !== "/" &&
          element !== "<" &&
          element !== ">" &&
          element !== "<=" &&
          element !== ">=" &&
          isNaN(element) !== false
        )
          acc.push(element);

        return acc;
      }, []);

    // Transforms elements into 'element': condition
    // Allows quick replacement in the initial expression
    const replaceValues = elements.reduce((obj, item) => {
      return {
        ...obj,
        [item]: verifyCondition(item, finalObject),
      };
    }, {});

    let evalExpression = expression;

    Object.keys(replaceValues).forEach((key) => {
      if (evalFlag == true || key.match(/text|value|otherText|ovalue|oText|isEmail/g)) {
        evalExpression = evalExpression.replaceAll(key, replaceValues[key]);
      }
    });
    if (evalFlag == true) {
      try {
        return eval(evalExpression);
      } catch (error) {
        Sentry.captureMessage(error);
      }
    } else {
      try {
        return evalExpression;
      } catch (error) {
        Sentry.captureMessage(error);
      }
    }
  }

  return expression;
};

export const replaceExpression = (
  apiInfo,
  section,
  finalObject,
  expression
) => {
  // Process the expression if it is provided
  if (expression) {
    const currentSectionIndex = section; // Assuming 'section' is the current section index
    let elements = [];
    // Accumulate question names from all sections up to the current one
    for (let sectionIndex = 0; sectionIndex <= currentSectionIndex; sectionIndex++) {
      elements = elements.concat(
        apiInfo.data.sections[sectionIndex].questions.map((element) => element.name)
      );
    }

    // Process the accumulated elements to identify placeholders in the expression
    elements = elements.reduce((acc, element) => {
      // Define possible suffixes for each question name
      const possibleValues = [
        ".text()",
        ".value()",
        ".otherText()",
        ".oText()",
      ];
      const maxOValue = 100; // Maximum index for ".ovalue(i)"
      const maxOText = 100; // Maximum index for ".oText(i)"
      const minOText = 96;  // Minimum index for ".oText(i)"
      for (let i = 1; i <= maxOValue; i++) {
        possibleValues.push(`.ovalue(${i})`);
      }
      for (let i = minOText; i <= maxOText; i++) {
        possibleValues.push(`.oText(${i})`);
      }

      // Check if the expression contains any of the question placeholders
      possibleValues
        .map((val) => element + val)
        .forEach((val) => {
          if (expression.includes(val)) {
            acc.push(val);
          }
        });
      return acc;
    }, []);

    // Generate replacement values by verifying conditions for each placeholder
    const replaceValues = elements.reduce((obj, item) => ({
      ...obj,
      [item]: verifyCondition(item, finalObject),
    }), {});

    let finalString = expression;
    // Replace each placeholder with its corresponding value
    Object.keys(replaceValues).forEach((key) => {
      finalString = finalString.replace(key, replaceValues[key]);
    });

    // Additional replacement: add display none to <img> tags with src="/false"
    finalString = finalString.replace(
      /<img([^>]*src=["']\/false["'][^>]*)>/g,
      '<img$1 style="display: none;">'
    );

    return finalString;
  }
  return expression;
};


export const transformAndEvaluate = (questionInfo, func) => {
  var type;
  try {
    type = questionInfo.questionType;
  } catch (e) { }

  switch (type) {
    case "SA":
      return evaluateSAfunction(questionInfo, func);
    case "MA":
      return evaluateMAfunction(questionInfo, func);
    case "OE":
      return evaluateOEfunction(questionInfo, func);
    case "SAPR":
      return evaluateSAPRfunction(questionInfo, func);
    case "INT":
      return evaluateINTfunction(questionInfo, func);
    case "SEA":
      return evaluateSEAfunction(questionInfo, func);
    case "OEPR":
      return evaluateOEPRfunction(questionInfo, func);
    case "INTPR":
      return evaluateINTPRfunction(questionInfo, func);
    case "MAPR":
      return evaluateMAPRfunction(questionInfo, func);
    case "SAPRC":
      return evaluateSAPRCfunction(questionInfo, func);
    case "DEC":
      return evaluateDECfunction(questionInfo, func);
    case "DECPR":
      return evaluateDECPRfunction(questionInfo, func);
    case "RANK":
      return evaluateRANKfunction(questionInfo, func);
    case "SCALE":
      return evaluateSCALEfunction(questionInfo, func);
    case "ASA":
      return evaluateASAfunction(questionInfo, func);
    case "ARAND":
      return evaluateARANDfunction(questionInfo, func);
    case "AMA":
      return evaluateAMAfunction(questionInfo, func);
    case "AFO":
      return evaluateAFOfunction(questionInfo, func);
    case "CITY":
      return evaluateCITYfunction(questionInfo, func);
    case "CE":
      return evaluateCEfunction(questionInfo, func);
    case "ECE":
      return evaluateECEfunction(questionInfo, func);
    case "HM":
      return evaluateHMfunction(questionInfo, func);
    default:
      return false;
  }
};

const evaluateSAfunction = (questionInfo, func) => {
  // Get function name
  const funcName = func.substring(0, func.indexOf("("));
  // Get argument or empty
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));

  // Utility to replace undefined or null with an empty string
  const safeReturn = (value) =>
    value === undefined || value === null ? "" : value;
  switch (funcName) {
    case "lt":
      return safeReturn(
        questionInfo.choices.length > 0 && questionInfo.choices[0] < arg
      );
    case "gt":
      return safeReturn(
        questionInfo.choices.length > 0 && questionInfo.choices[0] > arg
      );
    case "eq":
      return safeReturn(questionInfo.choices.includes(arg));
    case "text":
    case "otherText":
      // Ensure we're not accessing an index that doesn't exist
      return safeReturn(questionInfo.texts[questionInfo.choices[0] ?? ""]);
    case "value":
      // Convert to integer and ensure NaN is replaced by an empty string
      return safeReturn(parseInt(questionInfo.choices[0]));
    case "oText":
      return safeReturn(questionInfo.texts[parseInt(arg)]);

    default:
      return ""; // Directly return an empty string if none of the cases match
  }
};

const evaluateMAfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "has":
      return questionInfo.choices.includes(arg);
    case "count":
      return questionInfo.choices.length;
    case "oText":
      return questionInfo.texts[parseInt(arg)];
    default:
      return null;
  }
};

const evaluateOEfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "has":
      return questionInfo.choices[0].includes(arg);
    case "text":
      return questionInfo.choices[0];
    case "count":
      return questionInfo.choices[0].length;
    case "value":
      return parseInt(questionInfo.integerInputs[0]);
    case "isEmail":
      const key = questionInfo.questionId.toString();
      const email = questionInfo.texts[key];
      if (typeof email !== 'string') {
        return false;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    default:
      return null;
  }
};

const evaluateSAPRfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  let size = Object.keys(questionInfo.choices).length;
  switch (funcName) {
    case "olt":
      return size > 0 && parseInt(questionInfo.choices[arg1]) < parseInt(arg2);
    case "ogt":
      return size > 0 && parseInt(questionInfo.choices[arg1]) > parseInt(arg2);
    case "oeq":
      return parseInt(questionInfo.choices[arg1]) === parseInt(arg2);
    case "ovalue":
      return questionInfo.choices[arg1];
    default:
      return null;
  }
};

const evaluateINTfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));

  switch (funcName) {
    case "lt":
      return (
        questionInfo.integerInputs.length > 0 &&
        parseInt(questionInfo.integerInputs[0]) < parseInt(arg)
      );
    case "gt":
      return (
        questionInfo.integerInputs.length > 0 &&
        parseInt(questionInfo.integerInputs[0]) > parseInt(arg)
      );
    case "eq":
      return questionInfo.integerInputs === arg;
    case "has":
      const integerInputsHasArg = questionInfo.integerInputs.includes(arg);
      const choicesHasArg = Object.values(questionInfo.choices)
        .flat()
        .includes(arg);
      return integerInputsHasArg || choicesHasArg;
    case "value":
      return parseInt(questionInfo.integerInputs[0]);
    default:
      return null;
  }
};

const evaluateSEAfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "lt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] < arg;
    case "gt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] > arg;
    case "eq":
      return questionInfo.choices === arg;
    case "otherText":
      return questionInfo.texts[questionInfo.choices[0]];
    case "value":
      return parseInt(questionInfo.choices[0]);
    default:
      return null;
  }
};

const evaluateOEPRfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");

  // Utility to replace undefined or null with an empty string
  const safeReturn = (value) =>
    value === undefined || value === null ? "" : value;

  switch (funcName) {
    case "oeq":
      // Use the safeReturn function to ensure no undefined/null values are returned
      return safeReturn(questionInfo.choices[parseInt(arg1)] === arg2);
    case "ovalue":
      const index = parseInt(arg1); // Ensure arg1 is treated as an integer
      const value = questionInfo.choices[index];
      if (value !== undefined && value !== null) {
        return safeReturn(value); // Directly return the value if it's not undefined/null
      } else {
        // Return the corresponding text or an empty string if undefined/null
        return safeReturn(questionInfo.texts[index]);
      }
    default:
      return ""; // Directly return an empty string if none of the cases match
  }
};

const evaluateINTPRfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "olt":
      return (
        questionInfo.integerInputs.length > 0 &&
        questionInfo.integerInputs[parseInt(arg1)] < arg2
      );
    case "ogt":
      return (
        questionInfo.integerInputs.length > 0 &&
        questionInfo.integerInputs[parseInt(arg1)] > arg2
      );
    case "oeq":
      return questionInfo.integerInputs[parseInt(arg1)] === arg2;
    case "ovalue":
      return questionInfo.integerInputs[arg1];
    default:
      return null;
  }
};

const evaluateMAPRfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "has":
      return questionInfo.choices[parseInt(arg1)].includes(arg2);
    case "ohas":
      if (questionInfo.choices[parseInt(arg1)]) {
        return questionInfo.choices[parseInt(arg1)].includes(arg2);
      }
      return null;
    case "ovalue":
      return questionInfo.choices[arg1];
    default:
      return null;
  }
};

const evaluateSAPRCfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2, arg3] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "oheg":
      return questionInfo.choices[parseInt(arg1)][parseInt(arg2)] === arg3;
    case "ohgt":
      return questionInfo.choices[parseInt(arg1)][parseInt(arg2)] > arg3;
    case "ohlt":
      return questionInfo.choices[parseInt(arg1)][parseInt(arg2)] < arg3;
    default:
      return null;
  }
};

const evaluateDECfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "lt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] < arg;
    case "gt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] > arg;
    case "eq":
      return questionInfo.choices === arg;
    case "has":
      return questionInfo.choices.includes(arg);
    case "value":
      let value = questionInfo.integerInputs[0];
      value = parseInt(value);
      return parseInt(questionInfo.integerInputs[0]);
    default:
      return null;
  }
};

const evaluateDECPRfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "olt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] < arg2
      );
    case "ogt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] > arg2
      );
    case "oeq":
      return questionInfo.choices[parseInt(arg1)] === arg2;
    case "ovalue":
      return questionInfo.choices[arg1];
    default:
      return null;
  }
};

const evaluateRANKfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "olt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] < arg2
      );
    case "ogt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] > arg2
      );
    case "oeq":
      return questionInfo.choices[parseInt(arg1)] === arg2;
    case "ovalue":
      return questionInfo.choices[arg1];
    default:
      return null;
  }
};

const evaluateSCALEfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "olt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] < arg2
      );
    case "ogt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] > arg2
      );
    case "oeq":
      return questionInfo.choices[parseInt(arg1)] === arg2;
    case "ovalue":
      return questionInfo.choices[arg1];
    default:
      return null;
  }
};

const evaluateASAfunction = (questionInfo, func) => {
  // Get function name
  const funcName = func.substring(0, func.indexOf("("));
  // Get argument or empty

  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "lt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] < arg;
    case "gt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] > arg;
    case "eq":
      return questionInfo.choices.includes(arg);
    case "text":
      return questionInfo.questionText;
    case "otherText":
      return questionInfo.texts[questionInfo.choices[0]];
    default:
      return null;
  }
};

const evaluateARANDfunction = (questionInfo, func) => {
  // Get function name
  const funcName = func.substring(0, func.indexOf("("));
  // Get argument or empty
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "lt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] < arg;
    case "gt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] > arg;
    case "eq":
      return questionInfo?.choices?.includes(arg)
        ? questionInfo?.choices?.includes(arg)
        : questionInfo?.randomNumber == arg;
    case "otherText":
      return questionInfo.texts[questionInfo.choices[0]];
    default:
      return null;
  }
};

const evaluateAMAfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "has":
      return questionInfo.choices.includes(arg);
    case "count":
      return questionInfo.choices.length;
    case "oText":
      return questionInfo.texts[questionInfo.choices[parseInt(arg)]];
    default:
      return null;
  }
};

const evaluateAFOfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const arg = func.substring(func.indexOf("(") + 1, func.indexOf(")"));
  switch (funcName) {
    case "lt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] < arg;
    case "gt":
      return questionInfo.choices.length > 0 && questionInfo.choices[0] > arg;
    case "eq":
      return questionInfo.choices === arg;
    case "otherText":
      return questionInfo.texts[questionInfo.choices[0]];
    case "value":
      return parseInt(questionInfo.choices[0]);
    default:
      return null;
  }
};

const evaluateCITYfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  let cityDecompressed = LZString.decompress(localStorage.getItem("cities"));
  let cityJson = JSON.parse(cityDecompressed);
  const cityId = parseInt(questionInfo.choices.cityId);

  switch (funcName) {
    case "getINSArea":
      return cityJson[cityId].INSAreaId;
    case "getCounty":
      return questionInfo.choices["countyId"];
    case "getHistoricArea":
      return cityJson[cityId].historicAreaId;
    case "getSizeClassification":
      return cityJson[cityId].sizeClassificationId;
    case "value":
      return cityId;
    case "text":
      return cityJson[cityId].name;
    default:
      return null;
  }
};

const evaluateCEfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "has":
      return questionInfo.choices.includes(arg1);
    case "olt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] < arg2
      );
    case "ogt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] > arg2
      );
    case "oeq":
      return questionInfo.choices[parseInt(arg1)] === arg2;
    case "ovalue":
      return questionInfo.choices[arg1];
    default:
      return null;
  }
};

const evaluateECEfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "olt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] < arg2
      );
    case "ogt":
      return (
        questionInfo.choices.length > 0 &&
        questionInfo.choices[parseInt(arg1)] > arg2
      );
    case "oeq":
      return questionInfo.choices[parseInt(arg1)] === arg2;
    case "ovalue":
      return questionInfo.choices[arg1];
    default:
      return null;
  }
};

const evaluateHMfunction = (questionInfo, func) => {
  const funcName = func.substring(0, func.indexOf("("));
  const [arg1, arg2] = func
    .substring(func.indexOf("(") + 1, func.indexOf(")"))
    .trim()
    .split(",");
  switch (funcName) {
    case "has":
      return questionInfo.choices.includes(arg1);
  }
  return true;
};
