import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import MicRecorder from "mic-recorder-to-mp3";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Prompt } from "react-router-dom";
import {
  EmojiNeutral,
  EmojiSmile,
  EmojiSmileUpsideDown,
  Trash,
} from "react-bootstrap-icons";
import ImageMapper from "react-image-mapper";
import Select from "react-select";
import { Label, PopoverBody, UncontrolledPopover } from "reactstrap";
import HeaderForm from "../../componets/header/header.form.component.js";
import { getTheme, Themes } from "../../componets/themes/themes";
import { API_URL } from "../../constants/index.js";
import {
  evaluateExpression,
  replaceExpression,
} from "../../functions/evaluator";
import LZString from "lz-string";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { setTimer } from "../../functions/functions";
import { styled } from '@mui/material/styles';
import { Label as ReactstrapLabel } from 'reactstrap';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const id = urlParams.get("id");
var decreasedOptionsSAPR = 0;
var decreasedOptionsSA = 0;
var decreasedOptionsMA = 0;
var decreasedOptionsOEPR = 0;
var questionsGoneBack2 = 1;
var decreasedOptionsSAPRC = 0;
var display1 = false;

// Get height and width of screen
function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
  }, []);
  return size;
}

const indexedDB =
  window.indexedDB ||
  window.webkitIndexedDB ||
  window.mozIndexedDB ||
  window.OIndexedDB ||
  window.msIndexedDB,
  IDBTransaction =
    window.IDBTransaction ||
    window.webkitIDBTransaction ||
    window.OIDBTransaction ||
    window.msIDBTransaction;
let dbVersion = 2;

let request = indexedDB.open("recordings", dbVersion),
  db = null,
  createObjectStore = (dataBase) => {
    // Create an objectStore

    dataBase.createObjectStore("sounds");
  };

request.onupgradeneeded = function (event) {
  createObjectStore(event.target.result);
};

request.onerror = function (event) {
  console.log("You dont have access to indexedDb");
};

request.onsuccess = function (event) {
  db = request.result;
  console.log("db", db);
  db.onerror = function (event) { };
};
const Form = () => {
  const [apiInfo, setApiInfo] = useState(
    JSON.parse(localStorage.getItem("Object" + id))
  );
  const [inputDisabled, setInputDisabled] = useState([]);
  const [quotaInfo, setQuotaInfo] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [width] = useWindowSize();
  const [order, setOrder] = useState([]);
  const [emojiSelection, setEmojiSelection] = useState([]);
  const [shapeOrder, setShapeOrder] = useState([]);
  const [shapeColorName, setShapeColorName] = useState("");
  const [finalObject, setFinalObject] = useState({
    session: "",
    questions: [],
  });
  const [randomNumber, setRandomNumber] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countyOptions, setCountyOptions] = useState([]);
  const [textError, setTextError] = useState();
  const [section, setSection] = useState(0);
  const [coordonates, setCoordonates] = useState(
    JSON.parse(localStorage.getItem("coordonates"))
  );
  const [finished, setFinished] = useState(false);
  const [image, setImage] = useState("daadada");
  const [theme, setTheme] = useState(Themes.NormalTheme);
  const [disableButton] = useState(false);
  const [randomize, setRandomize] = useState(0);
  const [display, setDisplay] = useState("none");
  const [marginTop, setMarginTop] = useState("0px");
  // Timers
  var [seaOptions, setSeaOptions] = useState([]);
  const [language, setLanguage] = useState(0);
  const [Erorr, setError] = useState(false);
  const [projectText, setprojectText] = useState("Finding project...");
  const [dbModal, setDbModal] = useState(false);
  const [arrayOfOptions, setArrayOfOptions] = useState([]);
  const [rotateOptions, setRotateOptions] = useState(false);
  const [rotateHeaderOptions, setRotateHeaderOptions] = useState(false);
  const [randomizeOptions, setRandomizeOptions] = useState(false);
  const [randomizeHeaderOptions, setRandomizeHeaderOptions] = useState(false);
  const [showBox, setShowBox] = useState("block");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isQuestionnaireFinished, setIsQuestionnaireFinished] = useState(false);
  const [countFalseStartingQuestions, setCountFalseStartingQuestions] =
    useState(0);
  const handleCitySelectChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };
  const [mapReady, setMapReady] = useState(false);
  const handleMapReady = () => {
    setMapReady(true);
  };
  const [mapCreated, setMapCreated] = useState(false);

  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  const imgRef = useRef(null);

  const StyledLabel = styled(ReactstrapLabel)(({ theme }) => ({
    color: theme.palette.text_col.main,
    backgroundColor: theme.palette.background_col.main,
    fontWeight: 400,
    marginTop: '-2px',
    fontSize: '21px',
    marginLeft: '10px',
  }));

  function handleImageLoad(e) {
    if (!e || !e.target) {
      console.log("Event sau target este undefined", e);
      return;
    }
    const { naturalWidth, naturalHeight } = e.target;
    if (naturalHeight && naturalWidth) {
      setImgDimensions({ width: naturalWidth, height: naturalHeight });
    }
  }


  //Map for image
  const [map, setMap] = useState({
    name: "my-map",
    areas: [
      //   { name: "1", shape: "poly", coords: [1,160,250,50,650,500,400,650] },
      //   { name: "2", shape: "poly", coords: [390,192,395,124,983,124,983,328,574,328] },
    ],
  });
  useEffect(() => {
    setMap(map);
  }, [map]);
  //HERE
  useEffect(() => {
    if (apiInfo) {
      // go thru all questions and check if there are any false starting questions
      let count = 0;
      for (
        let i = 0;
        i < apiInfo.data.sections[section].questions.length;
        ++i
      ) {

        if ((apiInfo.data.sections[section].questions[i].condition == "false") || apiInfo.data.sections[section].questions[i].type === "ASA" ||
          apiInfo.data.sections[section].questions[i].type === "AMA" ||
          apiInfo.data.sections[section].questions[i].type === "AFO" ||
          apiInfo.data.sections[section].questions[i].type === "ARAND") {
          ++count;
          pickAnswerFromQuestion(apiInfo.data.sections[section].questions[i])
        } else {
          break;
        }

      }
      let condition =
        apiInfo.data.sections[section].questions[questionNumber].condition;
      if (condition == "false" ||
        apiInfo.data.sections[section].questions[questionNumber].type === "ASA" ||
        apiInfo.data.sections[section].questions[questionNumber].type === "AMA" ||
        apiInfo.data.sections[section].questions[questionNumber].type === "AFO" ||
        apiInfo.data.sections[section].questions[questionNumber].type === "ARAND") {
        setQuestionNumber(questionNumber + count);

        setCountFalseStartingQuestions(count);
      }
    }
  }, [apiInfo]);

  useEffect(() => {
    if (!mapCreated && apiInfo) {
      getImageUrl();

      createMap(false);
      setMapCreated(true);
    }
  }, [apiInfo, map]); // The emp

  useEffect(() => {
    if (isQuestionnaireFinished == false) {

      const handleBeforeUnload = (event) => {
        if (isFormDirty) {
          event.preventDefault();
          event.returnValue = ""; // Needed for Chrome
        }
      };
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isFormDirty, isQuestionnaireFinished]);

  const maxWidth = 1000;
  const maxHeight = 1100;
  const scaleFactor =
    imgDimensions.width && imgDimensions.height
      ? Math.min(maxWidth / imgDimensions.width, maxHeight / imgDimensions.height)
      : 1;
  const displayWidth = Math.round(imgDimensions.width * scaleFactor);
  const displayHeight = Math.round(imgDimensions.height * scaleFactor);

  // Funcția createMap actualizată:
  function createMap(isMapChanging, optionalShapeName) {
    if (!imgDimensions.width || !imgDimensions.height) {
      console.error("Dimensiunile imaginii nu au fost încărcate.");
      return;
    }

    // Calculul scale factor pentru coordonate (același ca cel folosit la redimensionare)
    const scaleX = displayWidth / imgDimensions.width; // == scaleFactor
    const scaleY = displayHeight / imgDimensions.height; // == scaleFactor

    const scaleCoordinates = (coords, scaleX, scaleY) => {
      return coords.map((coord, index) =>
        index % 2 === 0 ? Math.round(coord * scaleX) : Math.round(coord * scaleY)
      );
    };

    if (isMapChanging) {
      // Codul pentru modificarea zonelor existente
      let areas = map.areas;
      for (let i = 0; i < areas.length; ++i) {
        if (optionalShapeName === areas[i].name) {
          if (areas[i]?.preFillColor) {
            delete areas[i].preFillColor;
          } else {
            areas[i].preFillColor = "rgba(128, 128, 128, 0.5)";
          }
        }
      }
      setMap({ ...map, areas: [...areas] });
    } else {
      // Codul pentru crearea hărții pe baza opțiunilor din JSON
      let plusOrMinus = 0;
      for (let i = questionNumber - 2; i <= questionNumber + 2; ++i) {
        if (apiInfo?.data?.sections[section]?.questions[i]?.type === "HM") {
          plusOrMinus = i - questionNumber;
          break;
        }
      }
      const currentQuestion = apiInfo.data.sections[section].questions[questionNumber + plusOrMinus];
      const options = currentQuestion.options;
      map.areas = [];
      options.forEach((option) => {
        if (option.level === 2) {
          const coords = scaleCoordinates(
            option.text.split(",").map(Number),
            scaleX,
            scaleY
          );
          const area = {
            name: option.value - 1,
            shape: "poly",
            coords: coords,
          };
          map.areas.push(area);
        }
      });
      // Elimină duplicatele și actualizează starea
      const uniqueAreas = [];
      const seenNames = {};
      map.areas.forEach((area) => {
        if (!seenNames[area.name]) {
          uniqueAreas.push(area);
          seenNames[area.name] = true;
        }
      });
      setMap({ ...map, areas: [...uniqueAreas] });
      setTimeout(() => {
        handleMapReady();
        setMap({ ...map, areas: [...uniqueAreas] });
      }, 1500);
    }
  }



  //make createMap1 function to take the array of area names and create a map with them
  function createMap1(arr) {
    // if there is only 1 choice check to see if there is a checkbox with that id and check it
    setTimeout(() => {
      if (arr.length == 1) {
        let checkbox = document.getElementById(arr[0]);
        if (checkbox) {
          checkbox.checked = true;
        }
      }
    }, 1);

    let areas = map.areas;
    //reset prefillColor for all areas
    for (let i = 0; i < areas.length; ++i) {
      if (areas[i]?.preFillColor) {
        delete areas[i].preFillColor;
      }
    }
    //transform arr values to int
    arr = arr?.map((x) => parseInt(x));
    for (let i = 0; i < areas.length; i++) {
      if (arr.includes(parseInt(areas[i].name))) {
        if (areas[i]?.preFillColor) {
          // Delete preFillColor
          delete areas[i].preFillColor;
        } else {
          // Add preFillColor
          areas[i].preFillColor = "rgba(128, 128, 128, 0.5)";
        }
      }
    }
    setMap({ ...map, areas: [...areas] }); // Update the map with the modified areas
    // set shapeOrder to arr
    setShapeOrder(arr);
  }

  const startRecording = () => {
    Mp3Recorder.start()
      .then(() => { })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    createImagesUrls();
    setTimeout(() => {
      checkDb();
    }, 2000);
    if (apiInfo) {
      if (
        apiInfo.data.sections[section].questions[questionNumber].type === "CITY"
      ) {
        getOptionsCities();
        getOptionsCountys();
      }
    }
    if (questionNumber === 0 && apiInfo) {
      setTimeout(() => {
        document.getElementById("back-button").style.display = "none";
      }, 1);
    }
    setTimeout(() => {
      setprojectText(
        "Project was not found in the local storage, to fix this issue try resyncing from the resync button in the projects page"
      );
    }, 3000);
    getQuestions();

    getQuota();
    startRecording();
    let session = localStorage.getItem("session");
    for (let i = 1; i <= parseInt(session); ++i) {
      if (JSON.parse(localStorage.getItem("Object" + i))) {
        var object = JSON.parse(localStorage.getItem("Object" + i));

        if (
          object.questionnaireId === id &&
          object.status === "" &&
          object.questions.length > 0
        ) {
          let deletedQuestions = 0;

          while (
            object.questions[object.questions.length - 1 - deletedQuestions]
              .currentQuestionNumber == undefined
          ) {
            object.questions.splice(-1);
            ++deletedQuestions;
          }

          setFinalObject(object);
          setSection(object.questions[object.questions.length - 1 - deletedQuestions]
            .currentQuestionSection)
          setQuestionNumber(
            object.questions[object.questions.length - 1 - deletedQuestions]
              .currentQuestionNumber
          );

          setDisplay("none");
          localStorage.setItem("currentSession", JSON.stringify(parseInt(i)));
          break;
        } else {
          localStorage.setItem("session", JSON.stringify(parseInt(i) + 1));
          localStorage.setItem(
            "currentSession",
            JSON.stringify(parseInt(i) + 1)
          );
        }
      }
    }
  }, []);

  const getQuota = () => {
    const token = localStorage.getItem("token");
    return axios({
      withCredentials: true,
      method: "get",
      url: `${API_URL}/quotas/` + id,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setQuotaInfo(response.data);
        localStorage.setItem("quotas" + id, JSON.stringify(response.data));
      })
      .catch((err) => {
        const apiDataOflline = localStorage.getItem("quotas" + id);
        setQuotaInfo(JSON.parse(apiDataOflline));
        return null;
      });
  };
  // Get questions from API
  const getQuestions = () => {
    let api = LZString.decompress(localStorage.getItem("apiData" + id));
    setApiInfo(JSON.parse(api));
  };

  const [recordedBlob, setRecordedBlob] = useState(null);

  const putIntoDb = async (blob) => {
    try {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(blob);
          const transaction = db.transaction(["sounds"], "readwrite");
          const soundsStore = transaction.objectStore("sounds");
          const countRequest = soundsStore.count();

          countRequest.onsuccess = async (event1) => {
            let session = localStorage.getItem("session");
            let number = Number(session) + 1;
            soundsStore.put(blob, "sound" + number);
          };
        }, 0);
      });
    } catch {
      console.log("Not supported");
    }
  };
  const stopRecording = async () => {
    var [blob] = await Mp3Recorder.stop().getMp3();
    await putIntoDb(blob);
  };

  function checkIfScreened() {
    let options =
      apiInfo.data.sections[section].questions[questionNumber].options;
    let arrayOfScreenedAnswers = [];
    for (let i = 0; i < options.length; ++i) {
      if (options[i].IsEliminatory === true) {
        arrayOfScreenedAnswers.push(options[i].value);
      }
    }
    return arrayOfScreenedAnswers;
  }
  function checkIfQuota() {
    let options =
      apiInfo.data.sections[section].questions[questionNumber].options;
    let arrayOfQuotaAnswers = [];
    for (let i = 0; i < options.length; ++i) {
      if (options[i].IsOverQuota === true) {
        arrayOfQuotaAnswers.push(options[i].value);
      }
    }

    return arrayOfQuotaAnswers;
  }
  let error1 = false;
  // On question change backwards and forwards
  function changeQuestions(number, update) {

    setTimeout(() => {
      let errorShwed = document.getElementById("error").style.display;
      if (errorShwed != "block" && !update) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth", // Optional: Add smooth scrolling behavior
        });
      }
    }, 10);

    if (update != true) {
      setShapeOrder([]);
      setIsFormDirty(true);

      setRandomize(0);
    }

    if (questionNumber != 0) {
      document.getElementById("status-button").style.display = "";
      document.getElementById("back-button").style.display = "";
    }
    const currentQuestionName =
      apiInfo.data.sections[section].questions[questionNumber].name;
    let error = false;
    let textError = "";
    let screened = false;
    let quota = false;
    let nextQuestion = questionNumber + number;
    let dontchangeQuestion = 0;
    const currentQuestionType =
      apiInfo.data.sections[section].questions[questionNumber].type;
    const previousQuestionType =
      apiInfo?.data?.sections[section]?.questions[questionNumber - 1]?.type;
    const currentQuestionId =
      apiInfo.data.sections[section].questions[questionNumber].id;
    let minimumOptions =
      apiInfo.data.sections[section].questions[questionNumber].minimum;
    let maximumOptions =
      apiInfo.data.sections[section].questions[questionNumber].maximum;
    let session = localStorage.getItem("currentSession");
    if (nextQuestion > questionNumber || update == true) {
      display1 = false;
      setError(Erorr + 1);
      // const QUESTION_TYPES = ["SA","MA","OE","SAPR","INT","SEA","OEPR","INTPR","MAPR","SAPRC","DEC","DECPR","RANK","SCALE","ASA","ARAND","AMA","AFO","CITY","CE","ECE","HM"];
      let username = localStorage.getItem("currentUser");
      let formInputs =
        document.forms["questions-form"].getElementsByTagName("input");
      let textAreaInputs =
        document.forms["questions-form"].getElementsByTagName("textarea");
      let textInputs =
        document.forms["questions-form"].getElementsByTagName("input");
      let selectInputs =
        document.forms["questions-form"].getElementsByTagName("select");
      let object = {
        questionId: currentQuestionId,
        questionName: currentQuestionName,
        questionType: currentQuestionType,
        currentQuestionNumber: questionNumber,
        currentQuestionSection: section,
        choices: [],
        choiceId: [],
        texts: {},
        integerInputs: [],
      };
      let objectWithObjects = {
        questionId: currentQuestionId,
        questionName: currentQuestionName,
        questionType: currentQuestionType,
        currentQuestionNumber: questionNumber,
        currentQuestionSection: section,
        choices: {},
        texts: [],
        integerInputs: {},
      };
      let multipleObj = 0;

      if (questionNumber === countFalseStartingQuestions) {
        finalObject.session = session;
        finalObject.username = username;
        finalObject.timestamp = Date.now();
        finalObject.status = "";
        finalObject.coordonates = coordonates;
        finalObject.questionnaireId = id;
      }

      if (!update) {
        if (currentQuestionType === "HM" || previousQuestionType === 'HM') {
          getImageUrl();
        }

        //Eliminate all prefillCOlors from map
        if (mapCreated == true) {
          let areas = map.areas;
          for (let i = 0; i < areas.length; ++i) {
            if (areas[i]?.preFillColor) {
              delete areas[i].preFillColor;
            }
          }
          //remove duplicate areas
          areas = areas.filter(
            (area, index, self) =>
              self.findIndex((t) => {
                return (
                  t.name === area.name &&
                  t.shape === area.shape &&
                  t.coords === area.coords &&
                  t.preFillColor === area.preFillColor
                );
              }) === index
          );

          setMap({ ...map, areas: [...areas] }); // Update the map with the modified areas
        }
      }

      //if next question type is HM createMap
      if (
        apiInfo.data.sections[section]?.questions[nextQuestion]?.type ===
        "HM" &&
        update != true
      ) {
        setTimeout(() => {
          createMap(false);
        }, 2000);
      }

      finalObject.timers = JSON.parse(localStorage.getItem("timers"));
      if (currentQuestionType === "INFO") {
        let maximum = "";
        for (
          let i = 0;
          i < apiInfo.data.sections[section].questions.length;
          ++i
        ) {
          if (apiInfo.data.sections[section].questions[i].type === "ARAND") {
            maximum = apiInfo.data.sections[section].questions[i].maximum;
          }
        }

        setRandomNumber(Math.floor(Math.random() * maximum));

        error = false;
        error1 = true;
      } else {
      }

      let screenedAnswers = "";
      let quotaAnswers = "";

      if (update != true) {
        screenedAnswers = checkIfScreened();
        quotaAnswers = checkIfQuota();
      }

      if (
        currentQuestionType === "SA" ||
        currentQuestionType === "MA" ||
        currentQuestionType === "OE" ||
        currentQuestionType === "INT" ||
        currentQuestionType === "DEC"
      ) {
        if (currentQuestionType == "SA") {
          object.texts = {};
        }

        for (let i = 0; i < textAreaInputs.length; ++i) {
          if (
            currentQuestionType == "OE" &&
            textAreaInputs[i].value !== "" &&
            textAreaInputs[i].value !== "x"
          ) {
            object.texts[textAreaInputs[i].id] = textAreaInputs[i].value;
          }
        }

        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked === true) {
            object.choices.push(formInputs[i].id);
            object.texts[formInputs[i].id] = formInputs[i].dataset.text;
            object.choiceId.push(formInputs[i].dataset.id);
          }

          if (currentQuestionType == "MA") {
            if (formInputs[i].checked === true) {
              let input = document.getElementById(formInputs[i].id * 2);
              if (input) {
                if (input.value !== "on" && input.value != "" && !update) {
                  object.texts[formInputs[i].id] = input.value;
                } else if (input.value == "" && !update) {
                  error = true;
                  error1 = true;
                  textError = "Trebuie completat campul de text";
                }
              }
            }
          }
          if (
            formInputs[i].value !== "on" &&
            formInputs[i].value !== "" &&
            currentQuestionType !== "INT" &&
            currentQuestionType !== "DEC" &&
            currentQuestionType !== "MA"
          ) {
            object.texts[formInputs[i].id / 2] = formInputs[i].value;
            if (!formInputs[i].disabled && parseInt(formInputs[i].value)) {
              object.integerInputs.push(formInputs[i].value);
            }
          }
          
          if (
            formInputs[i].value === "on" &&
            currentQuestionType === "SA" &&
            formInputs[i].dataset.name === "other" &&
            formInputs[i].checked === true &&
            !update
          ) {
            let othersSAInput = formInputs[i].id * 2;
            othersSAInput = document.getElementById(othersSAInput.toString());
            if (othersSAInput.value == "") {
              error = true;
              error1 = true;
              textError = "Trebuie completat campul de text";
            }
          }
        }
        if (currentQuestionType == "SA") {
          if (Object.keys(object.texts).length > 1) {
            let keys = Object.keys(object.texts);
            delete object.texts[keys[keys.length - 1]];
          }
        }
        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked === true && formInputs[i].value !== "on") {
            // object.texts[formInputs[i].id * 2] = formInputs[i].value;
          }

          if (parseInt(formInputs[i].value) && !formInputs[i].disabled) {
            object.integerInputs.push(formInputs[i].value);
          }

          if (currentQuestionType === "INT") {
            let inputIntValue = document.getElementById("input-int");
            let checkedExclusive1 = document.getElementById("98");
            let checkedExclusive = document.getElementById("99");

            if (inputIntValue && checkedExclusive1) {
              // The elements exist, so we can safely access their values and properties
              inputIntValue = inputIntValue.value;
              let checkedExclusiveInt = checkedExclusive1.checked;

              if (inputIntValue !== "" && checkedExclusiveInt === true) {
                error = true;
                error1 = true;
                textError = "Varianta Niciunul este exclusiva!";
              }
            } else {
            }

            if (checkedExclusive1 && checkedExclusive) {
              checkedExclusive = checkedExclusive.checked;
              checkedExclusive1 = checkedExclusive1.checked;
              if (checkedExclusive1 === true && checkedExclusive === true) {
                error = true;
                error1 = true;
                textError =
                  "Varianta Niciunul este exclusiva! -- Varianta Refuz este exclusiva!";
              }
            }
          }

          if (formInputs[i].value === "00" && !formInputs[i].disabled) {
            object.integerInputs.push("00");
          }
        }

        if (
          apiInfo.data.sections[section].questions[questionNumber].maximum !== 0
        ) {
          if (
            currentQuestionType === "INT" &&
            object.integerInputs[0] >
            apiInfo.data.sections[section].questions[questionNumber].maximum
          ) {
            error = true;
            error1 = true;
            textError = "Number too high";
          }
          if (
            currentQuestionType === "INT" &&
            object.integerInputs[0] <
            apiInfo.data.sections[section].questions[questionNumber].minimum
          ) {
            error = true;
            error1 = true;
            textError = "Numarul minim este " + apiInfo.data.sections[section].questions[questionNumber].minimum;
          }
        }
        let size = Object.keys(object.texts).length;
        if (maximumOptions != 0) {
          if (size > maximumOptions && update != true) {
            error = true;
            error1 = true;
            textError = "Trebuie bifate numai " + maximumOptions + " optiuni";
          }
        }
        let size1 = object.integerInputs[0];
        // if question type is DEC and the integerInput is more then maximum and less then minimum show error
        if (currentQuestionType === "DEC") {
          if (size1 > maximumOptions && update != true) {
            error = true;
            error1 = true;
            textError =
              "Numarul maxim pe care il poti introduce este " + maximumOptions;
          }

          if (size1 < minimumOptions && update != true) {
            error = true;
            error1 = true;
            textError =
              "Numarul minim pe care il poti introduce este " + minimumOptions;
          }
        }
        if (
          object.choices.length === 0 &&
          size == 0 &&
          object.integerInputs.length == 0 &&
          update != true
        ) {
          error = true;
          error1 = true;
          textError = "Trebuie bifat cel putin " + minimumOptions + " optiune";
          if (currentQuestionType === "OE") {
            textError =
              "Scrie raspunsul sau bifeaza " + minimumOptions + " optiune";
          }

          if(currentQuestionType === "INT") {
            textError =
              "Numarul minim pe care il poti introduce este " + minimumOptions;
          }

          if (
            (currentQuestionType === "DEC" ||
              currentQuestionType === "MA" ||
              currentQuestionType === "SA") &&
            update !== true
          ) {
            textError =
              "Scrie raspunsul sau bifeaza " + minimumOptions + " optiune";
          }
        }
      }

      if (currentQuestionType === "SAPR") {
        ++multipleObj;
        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked == true) {
            objectWithObjects.choices[formInputs[i].name] = formInputs[i].id;
          }
        }
        let variantsLength = 0;
        for (
          let i = 0;
          i <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length;
          ++i
        ) {
          if (
            apiInfo.data.sections[section].questions[questionNumber].options[i]
              .level == 1
          ) {
            ++variantsLength;
          }
          if (
            apiInfo.data.sections[section].questions[questionNumber].options[i]
              .condition == "false"
          ) {
            --variantsLength;
          }
        }
        let size = Object.keys(objectWithObjects.choices).length;
        if (size < parseInt(variantsLength) - parseInt(decreasedOptionsSAPR)) {
          error = true;
          error1 = true;
          textError =
            "Trebuie bifat cel putin " +
            minimumOptions +
            " optiune pentru fiecare varianta";
        } else {
          decreasedOptionsSAPR = 0;
        }
      }

      if (currentQuestionType === "SEA") {
        if (textInputs[1].value == "") {
          error = true;
          error1 = true;
          textError =
            "Trebuie selectata cel putin " + minimumOptions + " optiune";
        } else {
          object.choices.push(textInputs[1].value);
        }
      }
      if (currentQuestionType === "CITY") {
        ++multipleObj;
        if (textInputs[1].value != "" && textInputs[3].value != "") {
          objectWithObjects.texts.push(textInputs[4]?.value ?? "");
          objectWithObjects.choices["countyId"] = textInputs[1].value;
          objectWithObjects.choices["cityId"] = textInputs[3].value;
        } else {
          error = true;
          error1 = true;
          textError = "Selecteaza un articol din lista";
        }
        let cityOthers = document.getElementById("city-others");
        if (!cityOthers?.value && cityOthers != null) {
          error = true;
          error1 = true;
          textError = "Trebuie completat campul de text";
        }
      }

      if (currentQuestionType === "OEPR") {
        object.choices = [];
        let disabledInputs = 0;
        for (let i = 0; i < formInputs.length; ++i) {
          if (
            formInputs[i].value != "" &&
            formInputs[i].value != "on" &&
            formInputs[i].disabled != true
          ) {
            object.texts[formInputs[i].name] = formInputs[i].value;
          }
          object.choices = [];
          if (
            formInputs[i].value != "" &&
            formInputs[i].value != "on" &&
            formInputs[i].disabled == "true"
          ) {
            object.choices.push(formInputs[i].value);
          }
          if (formInputs[i].disabled == true) {
            ++disabledInputs;
          }
        }

        for (let i = 0; i < formInputs.length; ++i) {
          if (
            formInputs[i].checked == true &&
            formInputs[i].type == "checkbox"
          ) {
            if (formInputs[i].id.includes("nustiu")) {
              object.choices.push(formInputs[i].id.substring(0, 1));
            } else {
              object.choices.push(formInputs[i].id);
            }
          }
        }

        for (let i = 0; i < Object.keys(object.texts).length; ++i) {
          if (object.choices[i] in object.texts) {
            object.choices.splice(i, 1);
            --i;
          }
        }

        let size = Object.keys(object.texts).length;
        let inputsHidden =
          apiInfo.data.sections[section].questions[questionNumber].options
            .length - formInputs.length;

        for (let k = 0; k < formInputs.length; ++k) {
          if (formInputs[k].type == "checkbox") {
            ++inputsHidden;
          }
        }
        if (
          size <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length -
          disabledInputs -
          inputsHidden
        ) {
          error = true;
          error1 = true;
          display1 = true;
          textError =
            "Trebuie bifat cel putin " +
            minimumOptions +
            " optiune pentru fiecare varianta";
        } else {
          decreasedOptionsOEPR = 0;
        }

        if (formInputs.length <= 0) {
          error = false;
          error1 = true;
        }
      }

      if (currentQuestionType === "INTPR") {
        let disabledInputs = 0;
        object.integerInputs = {};
        for (let i = 0; i < formInputs.length; ++i) {
          if (
            formInputs[i].value != "" &&
            formInputs[i].type == "number" &&
            formInputs[i].value != null &&
            formInputs[i].disabled == false
          ) {
            object.integerInputs[formInputs[i].id] = formInputs[i].value;
          }
          object.choices = [];
          if (
            formInputs[i].value != "" &&
            formInputs[i].value != "on" &&
            formInputs[i].disabled == "true"
          ) {
            object.choices.push(formInputs[i].value);
          }
          if (formInputs[i].disabled == true) {
            ++disabledInputs;
          }
        }
        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked == true) {
            object.choices.push(formInputs[i].id);
          }
        }

        let size = Object.keys(object.integerInputs).length;
        //calculate the sum of all the object.integerInputs values
        let sum = Object.values(object.integerInputs).reduce(
          (acc, arr) => parseInt(acc) + parseInt(arr),
          0
        );
        let maximum =
          apiInfo.data.sections[section].questions[questionNumber].maximum;
        let minimum =
          apiInfo.data.sections[section].questions[questionNumber].minimum;

        if (
          size <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length -
          disabledInputs &&
          size != formInputs.length &&
          maximum == 0
        ) {
          error = true;
          error1 = true;
          textError =
            "Trebuie scris cel putin " +
            minimumOptions +
            " optiune pentru fiecare varianta " +
            size +
            "";
        }
        if ((sum > maximumOptions || sum < minimumOptions) && (maximumOptions != 0 && minimumOptions != 1)) {
          error = true;
          error1 = true;
          textError =
            "Suma adunata a valorilor trebuie sa fie maxim " +
            maximum +
            " sau minim " +
            minimum +
            ". Suma curenta este: " +
            sum +
            "";
        }
      }

      if (currentQuestionType === "DECPR") {
        let disabledInputs = 0;
        ++multipleObj;
        objectWithObjects["choices"] = [];
        for (let i = 0; i < formInputs.length; ++i) {
          if (
            formInputs[i].value != "" &&
            formInputs[i].type == "number" &&
            formInputs[i].value != null &&
            formInputs[i].disabled == false
          ) {
            objectWithObjects.integerInputs[formInputs[i].id] =
              formInputs[i].value;
          }
          if (formInputs[i].disabled == true) {
            objectWithObjects.choices.push(formInputs[i].id);
          }
          if (formInputs[i].disabled == true) {
            ++disabledInputs;
          }
        }

        let size = Object.keys(objectWithObjects.integerInputs).length;
        if (
          size <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length -
          disabledInputs
        ) {
          error = true;
          error1 = true;
          textError =
            "Trebuie scris cel putin " +
            minimumOptions +
            " optiune pentru fiecare varianta";
        }
      }

      if (currentQuestionType === "MAPR") {
        ++multipleObj;
        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked == true) {
            objectWithObjects.choices[formInputs[i].name] = [];
          }
        }
        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked == true) {
            objectWithObjects.choices[formInputs[i].name].push(
              formInputs[i].id
            );
          }
        }
        let variantsLength = 0;
        for (
          let i = 0;
          i <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length;
          ++i
        ) {
          if (
            apiInfo.data.sections[section].questions[questionNumber].options[i]
              .level == 1
          ) {
            ++variantsLength;
          }
        }
        let variants;
        let size = Object.values(objectWithObjects.choices).reduce(
          (acc, arr) => acc + arr.length,
          0
        );
        
        variants = document.getElementsByClassName("panel-group").length;
        if (size < variants) {
          error = true;
          error1 = true;
          textError =
            "Trebuie bifat cel putin " +
            minimumOptions +
            " optiune pentru fiecare varianta";
        }

        decreasedOptionsSAPR = 0;
      }

      if (currentQuestionType === "SAPRC") {
        let variantsLength = 0;
        let attributesLenght = 0;
        let arrayOfAttributes = [];
        for (
          let i = 0;
          i <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length;
          ++i
        ) {
          if (
            apiInfo.data.sections[section].questions[questionNumber].options[i]
              .level == 2
          ) {
            ++variantsLength;
          }
          if (
            apiInfo.data.sections[section].questions[questionNumber].options[i]
              .level == 1
          ) {
            ++attributesLenght;
            arrayOfAttributes.push(
              apiInfo.data.sections[section].questions[questionNumber].options[
                i
              ].value
            );
          }
        }
        ++multipleObj;

        for (let i = 0; i < selectInputs.length; ++i) {
          objectWithObjects.choices[selectInputs[i].id] = {};
        }
        for (let i = 0; i < selectInputs.length; ++i) {
          if (selectInputs[i].value != "") {
            objectWithObjects.choices[selectInputs[i].id][
              selectInputs[i].name
            ] = selectInputs[i].options[selectInputs[i].selectedIndex].value;
          }
        }

        let size = 0;

        for (let i = 0; i < selectInputs.length; ++i) {
          if (!selectInputs[i].value) {
            error = true;
            error1 = true;
            textError = "Trebuie bifat toate optiunile pentru fiecare varianta";
          }
        }
      }

      if (currentQuestionType === "RANK") {
        ++multipleObj;
        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked == true) {
            objectWithObjects.choices[formInputs[i].name] = formInputs[i].id;
          }
        }
        let variantsLength = 0;
        let size = Object.keys(objectWithObjects.choices).length;
        for (
          let i = 0;
          i <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length;
          ++i
        ) {
          if (
            apiInfo.data.sections[section].questions[questionNumber].options[i]
              .level == 2
          ) {
            ++variantsLength;
          }
        }
        if (size < variantsLength) {
          error = true;
          error1 = true;
          textError =
            "Trebuie bifat cel putin " +
            minimumOptions +
            " optiune pentru fiecare varianta ";
        }
        for (let i = 1; i < size; ++i) {
          for (let j = i + 1; j <= size; ++j) {
            if (objectWithObjects.choices[i] == objectWithObjects.choices[j]) {
              error = true;
              error1 = true;
              textError =
                "Trebuie selectate valori diferite pentru fiecare rand";
              break;
            }
          }
        }
      }

      if (currentQuestionType === "SCALE") {
        ++multipleObj;
        for (let i = 0; i < formInputs.length; ++i) {
          if (formInputs[i].checked == true) {
            objectWithObjects.choices[formInputs[i].name] = formInputs[i].id;
          }
        }

        let variantsLength = 0;
        for (
          let i = 0;
          i <
          apiInfo.data.sections[section].questions[questionNumber].options
            .length;
          ++i
        ) {
          if (
            apiInfo.data.sections[section].questions[questionNumber].options[i]
              .level == 2
          ) {
            ++variantsLength;
          }
        }

        if (Object.keys(objectWithObjects.choices).length < variantsLength) {
          error = true;

          textError =
            "Trebuie bifat cel putin " +
            minimumOptions +
            " optiune pentru fiecare varianta";
        }

        if (error == false) {
          setDisplay("none");
        } else if (error == true && update != true) {
          setTextError(textError);

          setDisplay("block");
        }
      }

      if (currentQuestionType === "CE") {
        for (let i = 0; i < order.length; ++i) {
          object.choices.push(String(order[i]));
        }

        //if in finalObject is a question with the same id as the current question id and in that question there are choices add thoe choices to the object.choices
        for (let i = 0; i < finalObject.questions.length; ++i) {
          if (finalObject.questions[i].questionId === currentQuestionId) {
            for (let j = 0; j < finalObject.questions[i].choices.length; ++j) {
              object.choices.push(finalObject.questions[i].choices[j]);
            }
          }
        }
        if (Object.keys(object.choices).length < 1) {
          error = true;
          error1 = true;
          textError = "Selectia minima este " + minimumOptions;
        }
      }
      if (currentQuestionType === "ECE" && update != true) {
        for (let i = 1; i < 4; ++i) {
          object.choices.push(String(emojiSelection[i]));
        }
        if (Object.keys(object.choices).length < 1) {
          error = true;
          error1 = true;
          textError = "Selectia minima este " + minimumOptions;
        }
        let counterECE = 0;
        for (let i = 0; i < object.choices.length; ++i) {
          if (object.choices[i] == "undefined") {
            ++counterECE;
          }
        }

        if (counterECE == object.choices.length) {
          error = true;
          error1 = true;
          textError = "Selectia minima este " + minimumOptions;
        } else {
          error = false;
          error1 = true;
        }
      }

      if (currentQuestionType === "HM" && update != true) {
        for (let i = 0; i < shapeOrder.length; ++i) {
          object.choices.push(String(shapeOrder[i]));
        }
        //if   hm-checkbox is checked push id of checkbox
        let hmCheckbox = document.getElementsByName("hm-checkbox")[0];
        if (hmCheckbox.checked == true) {
          object.choices.push(hmCheckbox.id);
        }

        // No shape chosen
        if (shapeOrder.length == 0 && hmCheckbox.checked == false) {
          textError = "Trebuie sa selectezi cel putin o piesa!";
          setTextError(textError);
          setDisplay("block");

          error = true;
          error1 = true;
        }

        // Cant have both shape and checkbox checked
        // Search in shapeOrder if there is a choice with the id of the question that means that Niciunul is selected
        let noAnswer = false;
        for (let i = 0; i < shapeOrder.length; ++i) {
          if (shapeOrder[i] == currentQuestionId) {
            noAnswer = true;
            break;
          }
        }

        if (noAnswer == true && shapeOrder.length > 1) {
          textError = "Nu poti selecta si piesa si checkbox-ul!";
          setTextError(textError);
          setDisplay("block");

          error = true;
          error1 = true;
        }

        if (!error) {
          setDisplay("none");
          setFinished(true);
          setFinalObject({ ...finalObject });
          if (quotaInfo != null) {
            const newQuotaInfo = [...quotaInfo];
            for (let i = 0; i < newQuotaInfo.length; i++) {
              if (newQuotaInfo[i].option === null) {
                newQuotaInfo[i].completed++;
              }

              if (
                object.choiceId[0] &&
                newQuotaInfo[i].option === parseInt(object.choiceId[0])
              ) {
                newQuotaInfo[i].completed++;
                if (newQuotaInfo[i].completed > newQuotaInfo[i].target) {
                  newQuotaInfo[i].completed--;
                  // window.location.href = "/dashboard";
                  finalObject.status = "overquota";
                  localStorage.setItem(
                    "Object" + session,
                    JSON.stringify(finalObject)
                  );
                  setSection(apiInfo.data.sections.length - 1);
                  setQuestionNumber(2);
                  return;
                }
              }
            }
            setQuotaInfo(newQuotaInfo);
            localStorage.setItem(
              `quota${apiInfo.data.id}`,
              JSON.stringify(newQuotaInfo)
            );
          }
        }
      }
      let flagReplaced = false;
      if (multipleObj > 0) {
        for (let k = 0; k < finalObject.questions.length; ++k) {
          if (
            finalObject.questions[k].questionId === objectWithObjects.questionId
          ) {
            finalObject.questions[k] = objectWithObjects;
            flagReplaced = true;
          }
        }
        if (flagReplaced == false) {
          finalObject.questions.push(objectWithObjects);
        }
      } else {
        for (let k = 0; k < finalObject.questions.length; ++k) {
          if (finalObject.questions[k].questionId === object.questionId) {
            finalObject.questions[k] = object;
            flagReplaced = true;
          }
        }
        //End of questionnaire

        if (flagReplaced == false) {
          finalObject.questions.push(object);
        }
      }

      localStorage.setItem("Object" + session, JSON.stringify(finalObject));

      for (let i = 0; i < screenedAnswers.length; ++i) {
        for (let j = 0; j < object.choices.length; ++j) {
          if (screenedAnswers[i] == object.choices[j]) {
            screened = true;
          }
          if (quotaAnswers[i] == object.choices[j]) {
            quota = true;
          }
        }
      }

      //check objectWithObjects too for screened and quota
      for (let i = 0; i < screenedAnswers.length; ++i) {
        for (
          let j = 0;
          j < Object.keys(objectWithObjects.choices).length;
          ++j
        ) {
          if (
            screenedAnswers[i] == Object.values(objectWithObjects.choices)[j][0]
          ) {
            screened = true;
          }
          if (
            quotaAnswers[i] == Object.values(objectWithObjects.choices)[j][0]
          ) {
            quota = true;
          }
        }
      }

      if (screened == true && update != true && error != true) {
        //  set status of final object to screen
        finalObject.status = "screen";
        localStorage.setItem("Object" + session, JSON.stringify(finalObject));
        setDisplay("none");
        setSection(apiInfo.data.sections.length - 1);

        setQuestionNumber(1);
        //get last section available from apiInfo
        return;
      }

      if (
        nextQuestion >= 0 &&
        nextQuestion < apiInfo.data.sections[section].questions.length &&
        dontchangeQuestion < 1
      ) {
        if (screened == false || quota == false) {
          if (nextQuestion < questionNumber) {
            const otherExpression =
              apiInfo.data.sections[section].questions[nextQuestion]?.condition;
            if (
              otherExpression != null &&
              !evaluateExpression(otherExpression, finalObject)
            ) {
              setQuestionNumber(nextQuestion);
              changeQuestions(number + 1);
              return;
            }
          }

          if (error == false) {
            setDisplay("none");
          } else if (error == true && update != true) {
            setTextError(textError);

            setDisplay("block");
          }
        } else {
          if (quota == true && update != true) {
            finalObject.status = "overquota";
            localStorage.setItem(
              "Object" + session,
              JSON.stringify(finalObject)
            );
            setSection(apiInfo.data.sections.length - 1);
            setDisplay("none");
            setQuestionNumber(2);
            return;
          }
        }
      } else if (
        nextQuestion >= apiInfo.data.sections[0].questions.length &&
        screened == false &&
        error != true
      ) {
        //check if next section has questions
        if (
          apiInfo.data.sections[section + 1] &&
          apiInfo.data.sections[section + 1].questions.length > 0
        ) {
          setSection(section + 1);

          setQuestionNumber(0);
          return;
        } else {
          finalObject.status = "complete";
          localStorage.setItem("Object" + session, JSON.stringify(finalObject));
          setSection(apiInfo.data.sections.length - 1);

          setQuestionNumber(0);
          return;
        }
      }

      // Validation and conditonal show
      const conditionExpression =
        apiInfo.data.sections[section].questions[nextQuestion]?.condition;
      const validationExpression =
        apiInfo.data.sections[section].questions[questionNumber]?.validation;

      let validationMessage = false;
      if (
        validationExpression != null &&
        evaluateExpression(validationExpression, finalObject) &&
        error == false &&
        screened == false
      ) {
        ++dontchangeQuestion;
        error = true;
        error1 = true;
        validationMessage = true;
        setTextError(
          apiInfo.data.sections[section].questions[questionNumber]
            .validationMessage
        );

        setDisplay("block");
      } else {
        setDisplay("none");
      }

      if (quotaInfo != null && update != true) {
        const newQuotaInfo = [...quotaInfo];
        for (let i = 0; i < newQuotaInfo.length; i++) {
          if (
            object.choiceId[0] &&
            newQuotaInfo[i].option === parseInt(object.choiceId[0])
          ) {
            newQuotaInfo[i].completed++;

            if (newQuotaInfo[i].completed > newQuotaInfo[i].target) {
              finalObject.status = "overquota";
              localStorage.setItem(
                "Object" + session,
                JSON.stringify(finalObject)
              );
              setSection(apiInfo.data.sections.length - 1);

              setQuestionNumber(2);
              return;
            }
          }
        }
        setQuotaInfo(newQuotaInfo);
      }

      if (
        currentQuestionType == "ECE" ||
        (currentQuestionType == "HM" && error == true)
      ) {
        setDisplay("block");
      } else {
        if (validationMessage == false) {
          setTextError("");

          setDisplay("none");
        }
      }
      if (error == false) {
        setTextError("");

        setDisplay("none");
      }
      if (error == true) {
        if (textError.length != 0) {
          setTextError(textError);
        }
        setDisplay("block");
      }
      //take id of the previous question
      let previousQuestionId =
        apiInfo.data.sections[section].questions[questionNumber + 1].id;
      //search for the previous question in finalObject
      let previousQuestionObject = finalObject.questions.find(
        (question) => question.questionId == previousQuestionId
      );
      if (previousQuestionObject?.choices?.length > 0 && previousQuestionId.questionType === "HM") {
        createMap1(previousQuestionObject.choices);
      }
    } else {

      // search for the last HM question in finalObject
      let currentQuestion = apiInfo.data.sections[section].questions[questionNumber - 1].type === "HM";
      setShapeOrder([]);
      //take id of the previous question
      let previousQuestionId =
        apiInfo.data.sections[section].questions[questionNumber - 1].id;
      //search for the previous question in finalObject
      let previousQuestionObject = finalObject.questions.find(
        (question) => question.questionId == previousQuestionId
      );
      if (previousQuestionObject?.choices?.length > 0) {
        if (currentQuestion) {
          createMap1(previousQuestionObject.choices);
        }
      }
      setTextError("");
      setDisplay("none");
      let currentQuestionIndex = finalObject.questions.length;
      for (let i = 0; i < finalObject.questions.length; i++) {
        if (finalObject.questions[i].currentQuestionNumber == questionNumber) {
          currentQuestionIndex = i;
          break;
        }
      }

      for (let i = currentQuestionIndex - 1; i >= 0; i--) {
        if (finalObject.questions[i].currentQuestionNumber > 0) {
          setQuestionNumber(finalObject.questions[i].currentQuestionNumber);
          return;
        }
      }

      setDisplay("none");
    }

    let aheadQuestions = 0;
    let skipQuestions = false;
    let pickAnswerFromQuestionResponse = {
      error: false,
      message: "",
      shouldStopQuestionChange: false,
    };
    if (error != true) {
      for (
        let i = nextQuestion;
        i < apiInfo.data.sections[section].questions.length;
        i++
      ) {
        const nextQuestion = apiInfo.data.sections[section].questions[i];
        const questionType = nextQuestion.type;
        if (
          questionType === "ASA" ||
          questionType === "AMA" ||
          questionType === "AFO" ||
          (questionType === "ARAND" && nextQuestion.condition != "false")
        ) {
          if (questionType == "ARAND") {
            getRandomNumber();
          }

          pickAnswerFromQuestionResponse = pickAnswerFromQuestion(
            apiInfo.data.sections[section].questions[i]
          );
          if (pickAnswerFromQuestionResponse.shouldStopQuestionChange) {
            return;
          }

          ++aheadQuestions;
          continue;
        }
        if (
          evaluateExpression(nextQuestion.condition, finalObject) == true ||
          nextQuestion.condition == null
        ) {
          break;
        } else if (
          (!evaluateExpression(nextQuestion.condition, finalObject) ||
            nextQuestion.condition == "false") &&
          nextQuestion.condition != null
        ) {
          aheadQuestions++;
        }
      }

      if (
        update != true &&
        aheadQuestions > 0 &&
        error != true &&
        screened == false &&
        apiInfo.data.sections[section].questions[nextQuestion] &&
        quota == false
      ) {
        //if nextQuestion + aheadQuestions does not exists check next section
        if (
          !apiInfo.data.sections[section].questions[
          nextQuestion + aheadQuestions
          ]
        ) {
          if (
            apiInfo.data.sections[section + 1] &&
            apiInfo.data.sections[section + 1].questions.length > 0
          ) {
            setSection(section + 1);

            setQuestionNumber(0);
            return;
          } else {
            finalObject.status = "complete";
            localStorage.setItem(
              "Object" + session,
              JSON.stringify(finalObject)
            );
            setSection(apiInfo.data.sections.length - 1);
            setQuestionNumber(0);
            return;
          }
        }
        setQuestionNumber(nextQuestion + aheadQuestions);
      } else if (
        aheadQuestions == 0 &&
        error != true &&
        screened == false &&
        apiInfo.data.sections[section].questions[nextQuestion] &&
        quota == false
      ) {
        if (
          !apiInfo.data.sections[section].questions[
          nextQuestion + aheadQuestions
          ]
        ) {
          if (
            apiInfo.data.sections[section + 1] &&
            apiInfo.data.sections[section + 1].questions.length > 0
          ) {
            setSection(section + 1);

            setQuestionNumber(0);
            return;
          } else {
            finalObject.status = "complete";
            localStorage.setItem(
              "Object" + session,
              JSON.stringify(finalObject)
            );
            setSection(apiInfo.data.sections.length - 1);
            setQuestionNumber(0);
            return;
          }
        }
        setQuestionNumber(nextQuestion);
      }
    }
  }

  // Get options for react-select
  function getOptions(level, index) {
    var options = [];
    for (
      let i = 0;
      i <
      apiInfo.data.sections[section].questions[questionNumber].options.length;
      ++i
    ) {
      options.push({
        value:
          apiInfo.data.sections[section].questions[questionNumber].options[i]
            .value,
        label:
          apiInfo.data.sections[section].questions[questionNumber].options[i]
            .text,
        dataset:
          apiInfo.data.sections[section].questions[questionNumber].options[i]
            .id,
      });
    }
    seaOptions = options;
    return options;
  }

  function getOptionsCities(type) {
    if (
      document.forms["questions-form"] != undefined &&
      document.forms["questions-form"].getElementsByTagName("input")[2] !=
      undefined
    ) {
      let characterEntered =
        document.forms["questions-form"].getElementsByTagName("input")[2].value;
      if (
        document.forms["questions-form"].getElementsByTagName("input")[2]
          .value == "on"
      ) {
        characterEntered = "";
      }
      let textInputs =
        document.forms["questions-form"].getElementsByTagName("input");
      const cities = LZString.decompress(localStorage.getItem("cities"));
      const citiesData = JSON.parse(cities);
      const options = [];
      let char = "";
      if (characterEntered != undefined && characterEntered.length > 0) {
        char = characterEntered.toLowerCase();
        for (let i = 0; i < citiesData.length; ++i) {
          let string = citiesData[i].name;
          string = string.toLowerCase();
          if (
            string.includes(char) &&
            citiesData[i].countyId == textInputs[1].value
          ) {
            options.push({
              value: citiesData[i].id,
              label: citiesData[i].name,
            });
          }
          if (options.length > 80) {
            break;
          }
        }
        setCityOptions(options);
      } else {
        for (let i = 0; i < citiesData.length; ++i) {
          if (citiesData[i].countyId == textInputs[1].value) {
            options.push({
              value: citiesData[i].id,
              label: citiesData[i].name,
            });
          }
          if (options.length > 79) {
            break;
          }
        }

        setCityOptions(options);
      }
    }
  }

  function getOptionsCountys() {
    if (
      document.forms["questions-form"] != undefined &&
      document.forms["questions-form"].getElementsByTagName("input")[0] !=
      undefined
    ) {
      let characterEntered =
        document.forms["questions-form"].getElementsByTagName("input")[0].value;
      if (
        document.forms["questions-form"].getElementsByTagName("input")[0]
          .value == "on"
      ) {
        characterEntered = "";
      }
      const countys = LZString.decompress(localStorage.getItem("cities"));
      const citiesData = JSON.parse(countys);
      let options = [{}];
      let char = "";
      if (characterEntered != undefined && characterEntered.length > 0) {
        char = characterEntered.toLowerCase();
        for (let i = 0; i < citiesData.length; ++i) {
          let string = citiesData[i].county;
          string = string.toLowerCase();
          if (string.includes(char)) {
            options.push({
              value: citiesData[i].countyId,
              label: citiesData[i].county,
            });
          }
          if (options.length > 80) {
            break;
          }
        }
        options = options.filter(
          (option, index, self) =>
            index ===
            self.findIndex(
              (t) => t.value === option.value && t.label === option.label
            )
        );
      } else {
        const map = new Map();
        for (let i = 0; i < citiesData.length; ++i) {
          map.set(citiesData[i].county, citiesData[i].countyId);
        }
        for (const [key, value] of map) {
          options.push({ value: value, label: key });
        }

        setCountyOptions(options);
      }
    }
  }

  // Start of functions for non showing questions.
  // Get random number for ARAND type question
  function getRandomNumber() {
    const tempObject = {};
    for (let i = 0; i < apiInfo.data.sections[section].questions.length; ++i) {
      if (apiInfo.data.sections[section].questions[i].type == "ARAND") {
        tempObject["questionId"] =
          apiInfo.data.sections[section].questions[i].id;
        tempObject["questionName"] =
          apiInfo.data.sections[section].questions[i].name;
      }
    }
    tempObject["questionType"] = "ARAND";
    if (randomNumber == "") {
      tempObject["randomNumber"] = Math.floor(Math.random() * 10);
    } else {
      tempObject["randomNumber"] = randomNumber;
    }

    for (let k = 0; k < finalObject.questions.length; ++k) {
      if (finalObject.questions[k].questionId == tempObject["questionId"])
        finalObject.questions.splice(k, 1);
    }
    finalObject.questions.push(tempObject);
  }

  // Select answer ASA-AMA type questions
  function pickAnswerFromQuestion(question) {

    let session = localStorage.getItem("currentSession");
    let info = localStorage.getItem("Object" + session);
    info = JSON.parse(info);

    // Set response value
    let response = "";

    for (let i = 0; i < info?.questions?.length; ++i) {
      if (info?.questions[i]?.questionType === "SA") {
        response = info.questions[i].choices[0];
      }
    }

    let tempObjectq3 = {
      questionId: question.id,
      questionName: question.name,
      questionType: "AMA",
      choices: [],
      // questionIndex:
    };
    if (question.type === "ASA") {
      let ifExpressionTrue = false;
      //order the question.options by the field order in every option object
      let orderedOptions = question.options.sort((a, b) => {
        return a.order - b.order;
      });
      question.options = orderedOptions;
      for (let j = 0; j < question.options.length; j++) {
        const option = question.options[j];
        const condition = option.condition;
        const isEliminatory = option.IsEliminatory;
        const optionText = String(option.text);
        const optionValue = String(option.value);
        const expressionValue = evaluateExpression(condition, finalObject);
        if (expressionValue || condition == null) {
          ifExpressionTrue = true;

          const tempObject = {
            questionId: question.id,
            questionName: question.name,
            questionType: "ASA",
            questionText: optionText,
            choices: [optionValue],
          };

          finalObject.questions = finalObject.questions.filter(
            (q) => q.questionId !== question.id
          );
          finalObject.questions.push(tempObject);
          localStorage.setItem("Object" + session, JSON.stringify(finalObject));

          if (
            isEliminatory &&
            (evaluateExpression(condition, finalObject) == null ||
              evaluateExpression(condition, finalObject) == "" ||
              evaluateExpression(condition, finalObject) == true)
          ) {
            // PROBLEMA
            //set status of final object to screen
            finalObject.status = "screen";
            localStorage.setItem(
              "Object" + session,
              JSON.stringify(finalObject)
            );
            setSection(apiInfo.data.sections.length - 1);
            setQuestionNumber(1);

            return {
              error: false,
              message: "",
              shouldStopQuestionChange: true,
            };
          }
        }
        if (
          evaluateExpression(condition, finalObject) == null ||
          evaluateExpression(condition, finalObject) == "" ||
          evaluateExpression(condition, finalObject) == true
        ) {
          if (expressionValue !== false) {
            const tempObject = {
              questionId: question.id,
              questionName: question.name,
              questionType: "ASA",
              questionText: optionText,
              choices: [optionValue],
            };

            finalObject.questions = finalObject.questions.filter(
              (q) => q.questionId !== question.id
            );
            finalObject.questions.push(tempObject);
            localStorage.setItem(
              "Object" + session,
              JSON.stringify(finalObject)
            );

            return {
              error: false,
              message: "",
              shouldStopQuestionChange: false,
            };
          }
        }
      }
    }

    if (question.type == "AMA") {
      for (let j = 0; j < question.options.length; ++j) {
        let condition = question.options[j].condition;

        if (evaluateExpression(condition, finalObject)) {
          tempObjectq3["questionName"] = question.name;
          tempObjectq3.choices.push(String(question.options[j].value));
          if (question.options[j].IsEliminatory == true) {
            setTimeout(() => {
              //set status of final object to screen
              finalObject.status = "screen";
              localStorage.setItem(
                "Object" + session,
                JSON.stringify(finalObject)
              );
              setSection(apiInfo.data.sections.length - 1);
              setQuestionNumber(1);

              return {
                error: false,
                message: "",
                shouldStopQuestionChange: true,
              };
            }, 1);
            return {
              error: false,
              message: "",
              shouldStopQuestionChange: false,
            };
          }
        }
      }
    }

    if (question.type == "AFO") {
      let generateNumber = Number(response) + Number(randomNumber);
      finalObject.questions.push({
        questionId: question.id,
        questionName: question.name,
        questionType: "AFO",
        generatedNumber: [String(generateNumber)],
      });
      localStorage.setItem("Object" + session, JSON.stringify(finalObject));
    }

    if (question.type == "AMA") {
      for (let k = 0; k < finalObject.questions.length; ++k) {
        if (finalObject.questions[k].questionId == question.id)
          finalObject.questions.splice(k, 1);
      }
      finalObject.questions.push(tempObjectq3);
      localStorage.setItem("Object" + session, JSON.stringify(finalObject));
    }
    localStorage.setItem("Object" + session, JSON.stringify(finalObject));
    return {
      error: false,
      message: "",
      shouldStopQuestionChange: false,
    };
  }

  // End of functions for non showing questions.
  // Select order for text that was pressed from CE type question
  function clickOrder(textPressed) {
    let word = document.getElementById(textPressed);
    let paragraph = word.querySelector("p"); // Select the <p> inside the div

    if (paragraph.style.fontWeight > 700) {
      paragraph.style.fontWeight = 100;
      for (let i = 0; i < order.length; ++i) {
        if (order[i] == textPressed) {
          order.splice(i, 1);
        }
      }
    } else {
      order.push(textPressed);
      // Set font weight to bold for the selected <p> inside the div
      paragraph.style.fontWeight = 700;
    }
  }

  // Reset order selections of words CE type question
  function resetSelection() {
    let resetLength =
      apiInfo.data.sections[section].questions[questionNumber].options.length;
    for (let i = 1; i <= resetLength; ++i) {
      let element = document.getElementById(i);
      if (element) {
        let paragraph = element.querySelector('p');
        if (paragraph) {
          paragraph.style.fontWeight = '100'; // setează font-weight la 100 pentru <p>
        }
      }
    }
    setOrder([]);

    // reset in finalObject
    for (let i = 0; i < finalObject.questions.length; ++i) {
      if (finalObject.questions[i].questionId == apiInfo.data.sections[section].questions[questionNumber].id) {
        finalObject.questions[i].choices = [];
      }
    }
  }


  //Color changes for text with icons
  function changeTextColorToGreen(id, text, emoji) {
    let text_element = document.getElementById(id);
    if (text_element.style.color !== "green") {
      text_element.style.color = "green";
      let object = {};
      object.text = "1";

      // let temp = emojiSelection;
      // temp[0] = object[id.slice(-1)]
      // emojiSelection[0] = object[id.slice(-1)];
      emojiSelection[text] = "1";
      // setEmojiSelection({text:'1'})
    }
  }
  function changeTextColorToRed(id, text, emoji) {
    let text_element = document.getElementById(id);
    if (text_element.style.color !== "red") {
      text_element.style.color = "red";
      // let object = [];
      // object[id.slice(-1)] = "3";
      // emojiSelection.push(object[id.slice(-1)]);
      emojiSelection[text] = "3";
    }
  }
  function changeTextColorToOrange(id, text, emoji) {
    let text_element = document.getElementById(id);
    if (text_element.style.color !== "orange") {
      text_element.style.color = "orange";
      // let object = [];
      // object[id.slice(-1)] = "2";

      // emojiSelection.push(object[id.slice(-1)]);
      emojiSelection[text] = "2";
    }
  }
  function changeToDefaultColor(id, text) {
    let text_element = document.getElementById(id);
    emojiSelection[text] = "undefined";
    text_element.style.color = "royalblue";
  }
  function resetColorsOnEmojiText() {
    for (
      let i = 0;
      i <
      apiInfo.data.sections[section].questions[questionNumber].options.length;
      ++i
    ) {
      setTimeout(() => {
        let div_element = document.getElementById("text-emojis" + i).children;

        div_element[0].style.color = "royalblue";
      }, 100);
    }
    setEmojiSelection([]);
  }

  // Retain order of shapes clicked
  function retainOrderShapes(shapeName) {
    let countReset = 0;

    for (
      let i = 0;
      i <
      apiInfo.data.sections[section].questions[questionNumber].options.length;
      ++i
    ) {
      if (
        apiInfo.data.sections[section].questions[questionNumber].options[i]
          .level == 2
      ) {
        ++countReset;
      }
    }
    //HERE

    //if the shapeName is already in the shapeOrder array remove all instances of it
    if (shapeOrder.includes(shapeName)) {
      let index = shapeOrder.indexOf(shapeName);
      while (index > -1) {
        shapeOrder.splice(index, 1);
        index = shapeOrder.indexOf(shapeName);
      }
      setShapeOrder(shapeOrder);
    } else {
      setShapeColorName(shapeName);
      shapeOrder.push(shapeName);
      setShapeOrder(shapeOrder);
    }
    createMap(true, shapeName);
  }

  useEffect(() => {
    if (apiInfo) {
      setArrayOfOptions(
        apiInfo?.data?.sections[section].questions[questionNumber].options
      );
      setRotateOptions(
        apiInfo?.data?.sections[section].questions[questionNumber].rotateOptions
      );
      setRotateHeaderOptions(
        apiInfo?.data?.sections[section].questions[questionNumber]
          .rotateHeaderOptions
      );
      setRandomizeOptions(
        apiInfo?.data?.sections[section].questions[questionNumber]
          .randomizeOptions
      );
      setRandomizeHeaderOptions(
        apiInfo?.data?.sections[section].questions[questionNumber]
          .randomizeHeaderOptions
      );
    }
  }, [
    apiInfo,
    questionNumber,
    rotateOptions,
    rotateHeaderOptions,
    randomizeHeaderOptions,
    arrayOfOptions,
    setArrayOfOptions,
    setRotateOptions,
    setRotateHeaderOptions,
    setRandomizeOptions,
    setRandomizeHeaderOptions,
  ]);

  const rotatedOptionsMemoized = useMemo(() => {
    decreasedOptionsSAPR = 0;
    if (arrayOfOptions.length > 0) {
      let sortedOptions = arrayOfOptions.sort((a, b) => a.order - b.order);
      let clonedOptions = [...sortedOptions]; // Clone the array
      let arrayFlag = false;

      const specialOptions = clonedOptions.filter(
        (option) => option.value === 88 || option.value === 99 || option.value === 96
      );

      // Filter out options with value 88 or 99 or 96
      clonedOptions = clonedOptions.filter(
        (option) => option.value !== 88 && option.value !== 99 && option.value !== 96
      );

      if (randomizeHeaderOptions) {
        arrayFlag = true;
        const level2Options = clonedOptions.filter(
          (option) => option.level === 2
        );
        const level1Options = clonedOptions.filter(
          (option) => option.level === 1
        );
        const randomizedLevel2Options = level2Options.sort(
          () => Math.random() - 0.5
        );
        clonedOptions = [...randomizedLevel2Options, ...level1Options];
      }

      if (randomizeOptions) {
        arrayFlag = true;
        // Separate options into level 1 and others
        const level1Options = clonedOptions.filter(
          (option) => option.level === 1
        );
        const otherOptions = clonedOptions.filter(
          (option) => option.level !== 1
        );

        // Randomize level 1 options
        const randomizedLevel1Options = level1Options.sort(
          () => Math.random() - 0.5
        );

        // Combine the randomized level 1 options with other level options
        // This step assumes that the order of options in 'otherOptions' should remain as is,
        // and randomizedLevel1Options are combined in a way that respects their new randomized order
        clonedOptions = [...randomizedLevel1Options, ...otherOptions];
      }

      if (rotateHeaderOptions) {
        arrayFlag = true;
        const level2Options = clonedOptions.filter(
          (option) => option.level === 2
        );
        const randomRotate = Math.floor(Math.random() * level2Options.length);
        const rotatedLevel2Options = [
          ...level2Options.slice(randomRotate),
          ...level2Options.slice(0, randomRotate),
        ];
        const level1Options = clonedOptions.filter(
          (option) => option.level === 1
        );

        clonedOptions = [...rotatedLevel2Options, ...level1Options];
      }

      if (rotateOptions) {
        arrayFlag = true;
        const level1Options = clonedOptions.filter(
          (option) => option.level === 1
        );
        const randomRotate = Math.floor(Math.random() * level1Options.length);
        const rotatedLevel1Options = [
          ...level1Options.slice(randomRotate),
          ...level1Options.slice(0, randomRotate),
        ];
        const level2Options = clonedOptions.filter(
          (option) => option.level === 2
        );
        clonedOptions = [...level2Options, ...rotatedLevel1Options];
      }

      // Add the special options (88 or 99 or 96) at the end
      clonedOptions = [...clonedOptions, ...specialOptions];

      if (arrayFlag === false) {
        // sort the options by order if no special options are present
        clonedOptions = clonedOptions.sort((a, b) => a.order - b.order);
      }
      return clonedOptions;
    }

    return [];
  }, [
    arrayOfOptions,
    rotateOptions,
    rotateHeaderOptions,
    randomizeHeaderOptions,
    randomizeOptions,
  ]);

  const [processedOptions, setProcessedOptions] = useState([]);


  function showInputSA(id, checked) {
    let inputs = document.forms["questions-form"].getElementsByTagName("input");
    for (let i = 0; i < inputs.length; ++i) {
      if (inputs[i].type == "text") {
        document.getElementById(inputs[i].id).style.display = "none";
      }
    }
    if (checked === false) {
      document.getElementById(id).style.display = "none";
      document.getElementById(id).innerHTML = "";
    } else {
      document.getElementById(id).style.display = "block";
    }
  }

  function showInput(id, checked) {
    if (checked === false) {
      document.getElementById(id).style.display = "none";
      document.getElementById(id).innerHTML = "";
    } else {
      document.getElementById(id).style.display = "block";
    }
  }

  function checkInputs() {
    let inputs = document.forms["questions-form"].getElementsByTagName("input");
    for (let i = 0; i < inputs.length; ++i) {
      if (inputs[i].type == "text") {
        document.getElementById(inputs[i].id).innerHTML = "";
        document.getElementById(inputs[i].id).style.display = "none";
      }
    }
  }

  async function endQuestions(status) {
    setIsQuestionnaireFinished(true);
    let spare = finalObject;
    for (let i = 0; i < spare.questions.length; ++i) {
      if (spare.questions[i].questionType == "ECE") {
        for (let j = 0; j < spare.questions[i].choices.length; ++j) {
          if (spare.questions[i].choices[j] == "undefined") {
            spare.questions[i].choices.splice(j, 1);

            --j;
          }
        }
      }
    }
    setFinalObject(spare);

    let session = localStorage.getItem("session");
    let currentSession = localStorage.getItem("currentSession");

    if (status == "screen") {
      finalObject["status"] = "screen";
    }
    if (status == "complete") {
      finalObject["status"] = "complete";
    }
    if (status == "overquota") {
      finalObject["status"] = "overquota";
    }
    setDisplay("none");
    setFinished(true);
    setFinalObject({ ...finalObject });
    await stopRecording();
    setTimeout(() => {
      localStorage.setItem("Object" + session, JSON.stringify(finalObject));
      localStorage.setItem("session", Number(session) + 1);
      localStorage.setItem("currentSession", Number(currentSession) + 1);
      if (process.env.NODE_ENV === "development") {
        window.location.href = "/projects";
      } else {
        window.location.href = "/offline_quiz/projects";
      }
    }, 1800);
  }

  function disableInput(id) {
    display1 = true;
    if (document.getElementById(id).disabled == false) {
      document.getElementById(id).disabled = true;
    } else {
      document.getElementById(id).disabled = false;
    }
  }

  function disableInputs(id, condition) {
    let inputs = document.forms["questions-form"].getElementsByTagName("input");
    let exclusiveInput;

    for (let i = 0; i < inputs.length; ++i) {
      if (inputs[i].id == id) {
        exclusiveInput = inputs[i];
      }
    }

    if (condition == "condition") {
      for (let i = 0; i < inputs.length; ++i) {
        if (inputs[i].dataset.name == "exclusive") {
          document.getElementById(inputs[i].id).checked = false;
        }
      }
    }
    if (condition !== "condition") {
      if (exclusiveInput.checked == true) {
        for (let i = 0; i < inputs.length; ++i) {
          if (id != inputs[i].id) {
            inputs[i].checked = false;
          }
          // if (id != inputs[i].id) {
          //   inputs[i].disabled = true
          // }
        }
      } else {
        for (let i = 0; i < inputs.length; ++i) {
          if (id != inputs[i].id) {
            inputs[i].disabled = false;
          }
        }
      }
    }
  }

  const handleThemeChange = () => {
    if (theme === Themes.DarkTheme) {
      setTheme(Themes.NormalTheme);
    } else {
      setTheme(Themes.DarkTheme);
    }
  };

  function ifChecked(idVariant, idCheckbox) {
    var da = false;
    var currentQuestionType =
      apiInfo.data.sections[section].questions[questionNumber].type;

    var currentQuestionId =
      apiInfo.data.sections[section].questions[questionNumber].id;

    for (let i = 0; i < finalObject.questions.length; ++i) {
      if (currentQuestionId != finalObject.questions[i].questionId) {
        continue;
      }

      const idCheckboxFinalObject = finalObject.questions[i].choices[idVariant];

      if (currentQuestionType == "OEPR") {
        if (display1 == false) {
          var idCheckboxDisable = finalObject.questions[i].choices;
          for (let j = 0; j < idCheckboxDisable.length; ++j) {
            if (idCheckboxDisable[j] == idCheckbox) {
              setTimeout(() => {
                document.getElementById(idVariant).disabled = true;
                document.getElementById(idCheckbox).checked = true;
              }, 1);
              da = true;
            }
          }
        }
      }

      if (currentQuestionType == "INTPR") {
        var idCheckboxDisable = finalObject.questions[i].choices;
        for (let j = 0; j < idCheckboxDisable.length; ++j) {
          if (idCheckboxDisable[j] == idCheckbox) {
            setTimeout(() => {
              document.getElementById(idCheckbox).disabled = true;
            }, 1);
            da = true;
          }
        }
      }

      if (currentQuestionType == "DECPR") {
        var idCheckboxDisable = finalObject.questions[i].choices;
        for (let j = 0; j < idCheckboxDisable.length; ++j) {
          if (idCheckboxDisable[j] == idCheckbox) {
            setTimeout(() => {
              document.getElementById(idCheckbox).disabled = true;
            }, 1);
            da = true;
          }
        }
      }

      if (currentQuestionType == "RANK") {
        if (idCheckbox == idCheckboxFinalObject) {
          da = true;
        }
      }

      if (currentQuestionType == "SAPR") {
        if (idCheckbox == idCheckboxFinalObject) {
          da = true;
        }
      }

      if (currentQuestionType == "MAPR") {
        if (idCheckboxFinalObject) {
          for (let j = 0; j < idCheckboxFinalObject.length; ++j) {
            if (idCheckboxFinalObject[j] == idCheckbox) {
              da = true;
            }
          }
        }
      }
      if (currentQuestionType == "SA") {
        if (idCheckbox == finalObject.questions[i].choices[0]) {
          da = true;
        }
      }
      if (currentQuestionType == "MA") {
        for (let j = 0; j < finalObject.questions[i].choices.length; ++j) {
          if (idCheckbox == finalObject.questions[i].choices[j]) {
            da = true;
          }
        }
      }
      if (currentQuestionType == "INT") {
        for (let j = 0; j < finalObject.questions[i].choices.length; ++j) {
          if (idCheckbox == finalObject.questions[i].choices[j]) {
            da = true;
          }
        }
      }
      if (currentQuestionType == "OE") {
        for (let j = 0; j < finalObject.questions[i].choices.length; ++j) {
          if (idCheckbox == finalObject.questions[i].choices[j]) {
            da = true;
          }
        }
      }
      if (currentQuestionType == "SCALE") {
        if (idCheckbox == idCheckboxFinalObject) {
          da = true;
        }
      }
      if (currentQuestionType == "CE") {
        if (idCheckbox == idCheckboxFinalObject) {
          da = true;
        }
      }
    }
    if (da) {
      return da;
    }
  }

  function ifValue(idRow, idInput, id, cityOrCounty) {
    let session = localStorage.getItem("session");
    localStorage.setItem("Object" + session, JSON.stringify(finalObject));
    var currentQuestionId =
      apiInfo.data.sections[section].questions[questionNumber].id;
    var currentQuestionType =
      apiInfo.data.sections[section].questions[questionNumber].type;
    for (let i = 0; i < finalObject.questions.length; ++i) {
      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "OE"
      ) {
        return finalObject.questions[i].texts[currentQuestionId];
      }
      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "SA"
      ) {
        if (idRow == Object.keys(finalObject.questions[i].texts)[0] * 2) {
          setTimeout(() => {
            // Insert text in option that has text
            const domElement = document.getElementById(
              Object.keys(finalObject.questions[i].texts)[0] * 2
            );

            if (domElement) {
              domElement.style.display = "block";
            }
          }, 2);

          let checkboxParent = document.getElementById(idRow / 2);
          let text =
            finalObject.questions[i].texts[
            Object.keys(finalObject.questions[i].texts)[0]
            ];
          if (checkboxParent != null && text == checkboxParent.dataset.text) {
            return "";
          } else {
            return text;
          }
        }
      }
      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "MA"
      ) {
        for (
          let j = 0;
          j < Object.keys(finalObject.questions[i].texts).length;
          ++j
        ) {
          if (idRow == Object.keys(finalObject.questions[i].texts)[j] * 2) {
            setTimeout(() => {
              const domElement = document.getElementById(
                Object.keys(finalObject.questions[i].texts)[j] * 2
              );

              if (domElement) {
                domElement.style.display = "block";
              }
            }, 2);

            return finalObject.questions[i].texts[
              Object.keys(finalObject.questions[i].texts)[j]
            ];
          }
        }
      }

      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "INT"
      ) {
        return finalObject.questions[i].integerInputs[0];
      }

      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "OEPR"
      ) {
        for (
          let j = 0;
          j < Object.keys(finalObject.questions[i].texts).length;
          ++j
        ) {
          if (idRow == Object.keys(finalObject.questions[i].texts)[j]) {
            return finalObject.questions[i].texts[
              Object.keys(finalObject.questions[i].texts)[j]
            ];
          }
        }
      }
      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "INTPR"
      ) {
        for (
          let j = 0;
          j < Object.keys(finalObject.questions[i].integerInputs).length;
          ++j
        ) {
          if (idRow == Object.keys(finalObject.questions[i].integerInputs)[j]) {
            return finalObject.questions[i].integerInputs[
              Object.keys(finalObject.questions[i].integerInputs)[j]
            ];
          }
        }
      }
      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "DEC"
      ) {
        return finalObject.questions[i].integerInputs[0];
      }
      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "DECPR"
      ) {
        for (
          let j = 0;
          j < Object.keys(finalObject.questions[i].integerInputs).length;
          ++j
        ) {
          if (idRow == Object.keys(finalObject.questions[i].integerInputs)[j]) {
            return finalObject.questions[i].integerInputs[
              Object.keys(finalObject.questions[i].integerInputs)[j]
            ];
          }
        }
      }
      if (
        currentQuestionId == finalObject.questions[i].questionId &&
        currentQuestionType == "CITY"
      ) {
        if (cityOrCounty == "county") {
          let obj = countyOptions.find(
            (o) =>
              o.value === parseInt(finalObject.questions[i].choices.countyId)
          );
          return obj;
        } else {
          let obj = cityOptions.find(
            (o) => o.value === parseInt(finalObject.questions[i].choices.cityId)
          );
          return obj;
        }
      }
    }
  }

  function test(rowId, selectId) {
    var currentQuestionType =
      apiInfo.data.sections[section].questions[questionNumber].type;
    const currentQuestion = finalObject.questions.filter(
      (e) => e.questionType === currentQuestionType
    );
    if (currentQuestion.length == 0) {
      return null;
    }

    return currentQuestion[0].choices[rowId][selectId];
  }

  function fillWords(id) {
    var currentQuestionType =
      apiInfo.data.sections[section].questions[questionNumber].type;
    const currentQuestion = finalObject.questions.filter(
      (e) => e.questionType === currentQuestionType
    );

    if (currentQuestion.length == 0) {
      return null;
    }

    for (let j = 0; j < currentQuestion[0].choices.length; ++j) {
      if (id == currentQuestion[0].choices[j]) {
        setTimeout(() => {
          let element = document.getElementById(id);
          if (element) {
            let paragraph = element.querySelector('p'); // Găsește tag-ul <p> din interiorul div-ului
            if (paragraph) {
              paragraph.style.fontWeight = "bold"; // Aplică bold doar pe <p>
            }
          }
        }, 2);

        break;
      }
    }
  }

  function fillButtons(id, index) {
    let session = localStorage.getItem("currentSession");
    let obj = JSON.parse(localStorage.getItem("Object" + session));

    var currentQuestionId =
      apiInfo.data.sections[section].questions[questionNumber].id;
    const currentQuestion = obj.questions.filter(
      (e) => e.questionId === currentQuestionId
    );

    if (currentQuestion[0]) {
      if (currentQuestion[0].choices[id - 1] == "1") {
        setTimeout(() => {
          document.getElementById("Popover1" + index).style.color = "green";
        }, 2);
      }
      if (currentQuestion[0].choices[id - 1] == "2") {
        setTimeout(() => {
          document.getElementById("Popover1" + index).style.color = "orange";
        }, 3);
      }
      if (currentQuestion[0].choices[id - 1] == "3") {
        setTimeout(() => {
          document.getElementById("Popover1" + index).style.color = "red";
        }, 4);
      }
    }
  }

  function returnSea() {
    var currentQuestionType =
      apiInfo.data.sections[section].questions[questionNumber].type;
    const currentQuestion = finalObject.questions.filter(
      (e) => e.questionType === currentQuestionType
    );

    if (currentQuestion.length == 0) {
      return null;
    }
    let value = seaOptions.find(
      (x) => x.label === "item " + currentQuestion[0].choices[0]
    );

    return value;
  }
  const handleChangeLang = (event) => {
    localStorage.setItem("language", event.target.value);
    setLanguage(event.target.value);
  };

  function checkLanguage(type, id) {
    const regex = /<([^\/][^\s]*?).*?(?:\/>|>(?:.|\n)*?<\/\1>)/g;
    const subst = ``;
    var language = localStorage.getItem("language");
    var currentQuestionId =
      apiInfo.data.sections[section].questions[questionNumber].id;

    for (let i = 0; i < apiInfo.data.translations.length; ++i) {
      if (
        apiInfo.data.translations[i].type == type &&
        language == apiInfo.data.translations[i].language &&
        currentQuestionId == apiInfo.data.translations[i].id
      ) {
        return apiInfo.data.translations[i].text;
      }
    }
    if (type == 3) {
      for (let i = 0; i < apiInfo.data.translations.length; ++i) {
        if (
          id == apiInfo.data.translations[i].id &&
          language == apiInfo.data.translations[i].language
        ) {
          return apiInfo.data.translations[i].text.replace(regex, subst);
        }
      }
    }
  }
  function getImageUrl() {
    var imgURL;
    var request = window.indexedDB.open("images");

    let userSearchDB, userSearchDBstore, userSearchDBtx;
    request.onsuccess = function (event) {
      userSearchDB = event.target.result;
      userSearchDBtx = userSearchDB.transaction("images", "readwrite");
      userSearchDBstore = userSearchDBtx.objectStore("images");
      // let getAllRequest = userSearchDBstore.getAll()
      var imgFromApi = "";
      var imgFromApiToCompare = "";
      for (let i = 0; i < apiInfo.data.sections[0].questions.length; ++i) {
        if (apiInfo.data.sections[0].questions[i].type == "HM") {
          for (
            let j = 0;
            j < apiInfo.data.sections[0].questions[i].options.length;
            ++j
          ) {
            if (apiInfo.data.sections[0].questions[i].options[j].level == 1) {
              // Get the condition from the API
              const condition =
                apiInfo.data.sections[0].questions[i].options[j].condition;
              let shouldSetImage = evaluateExpression(condition, finalObject);
              if (shouldSetImage == "") {

                imgFromApi =
                  apiInfo.data.sections[0].questions[i].options[j].text;

                // Get the image from the database
                for (let k = 0; k < imgFromApi.length; ++k) {
                  if (imgFromApi[k] == "/") {
                    imgFromApiToCompare = "";
                    ++k;
                  }
                  imgFromApiToCompare += imgFromApi[k];
                }

                (function (store, imgFromApiToCompare) {
                  var getKey = store.get(imgFromApiToCompare);

                  getKey.onsuccess = function (eventSuccess) {
                    const imgFile = eventSuccess.target.result;
                    const URLCreator = window.URL || window.webkitURL;
                    let localImgURL;
                    if (imgFromApiToCompare && imgFile) {
                      localImgURL = URLCreator.createObjectURL(imgFile);
                    }
                    if (localImgURL) {
                      setImage(localImgURL);
                    } else {
                      console.error("Nu s-a creat URL-ul imaginii sau blob-ul este invalid");
                    }
                  };
                })(userSearchDBstore, imgFromApiToCompare);

                // Error handling remains unchanged
              }
              // If shouldSetImage is false, simply continue to the next option
            }
          }
        }
      }
    };
  }

  function createImagesUrls() {
    var request = window.indexedDB.open("images");
    let imageNamesAndBlobs = {};

    let userSearchDB, userSearchDBstore, userSearchDBtx;
    request.onsuccess = function (event) {
      userSearchDB = event.target.result;
      userSearchDBtx = userSearchDB.transaction("images", "readwrite");
      userSearchDBstore = userSearchDBtx.objectStore("images");
      let getKeys = userSearchDBstore.openCursor();

      getKeys.onsuccess = function (event) {
        let cursor = event.target.result;
        if (cursor) {
          let key = cursor.primaryKey;
          let value = cursor.value;
          var URL = window.URL || window.webkitURL;
          var imageLink = URL.createObjectURL(value);
          imageNamesAndBlobs["/static/" + key] = imageLink;

          cursor.continue();
        } else {
          // no more results
        }
      };

      userSearchDBtx.onerror = function () { };

      userSearchDBtx.oncomplete = function () {
        userSearchDB.close();
      };
    };
    setImagesUrl(imageNamesAndBlobs);
  }
  function checkDb() {
    if (db != null) setDbModal(false);
    else setDbModal(true);
  }

  if (apiInfo === null) {
    return <h1>{projectText}</h1>;
  } else {
    return (
      <ThemeProvider theme={getTheme(theme)}>
        <Box className="form-container" sx={{ bgcolor: "background_col.main" }}>
          <HeaderForm
            handleThemeChange={handleThemeChange}
            stopRecording={stopRecording}
            handleChangeLang={handleChangeLang}
            setIsQuestionnaireFinished={setIsQuestionnaireFinished}
          />
          <Prompt
            when={isFormDirty}
            message="Are you sure you want to leave?"
          />
          <Popup open={dbModal} position="right center">
            <div>
              The recordings of the questionnaire will not be able to be saved
              as this version of browser does not support it.
            </div>
          </Popup>
          <Box className="content" sx={{ bgcolor: "background_col.main" }}>
            <Box
              className="row effect6 row-form"
              sx={{ bgcolor: "background_col.main", display: showBox }}
            >
              <Box
                className="col-md-12 col-lg-12"
                sx={{ bgcolor: "background_col.main" }}
              >
                <Box
                  className="media-form"
                  sx={{ bgcolor: "background_col.main" }}
                >
                  <form
                    className="questions-form"
                    name="questions-form"
                    id="questions-form"
                  >
                    <Typography
                      variant="h5"
                      className="question-number"
                      sx={{ color: "text_col.main" }}
                    >
                      {
                        apiInfo.data.sections[section].questions[questionNumber]
                          .name
                      }
                    </Typography>
                    <Box component="span" sx={{ color: "text_col.main" }}>
                      <Typography className="question-text">
                        {(() => {
                          let questionText =
                            checkLanguage(1) == null
                              ? apiInfo.data.sections[section].questions[
                                questionNumber
                              ].text
                              : checkLanguage(1);

                          let counter = 0;
                          while (counter < 2) {
                            questionText = replaceExpression(
                              apiInfo,
                              section,
                              finalObject,
                              replaceExpression(
                                apiInfo,
                                section,
                                finalObject,
                                questionText
                              )
                            );
                            counter++;
                          }

                          return (
                            <div
                              dangerouslySetInnerHTML={{ __html: questionText }}
                            />
                          );
                        })()}
                      </Typography>
                    </Box>
                    <Box component="span" sx={{ color: "text_col.main" }}>
                      <Typography
                        className="question-instruction"
                        dangerouslySetInnerHTML={{
                          __html: replaceExpression(
                            apiInfo,
                            section,
                            finalObject,
                            checkLanguage(2) == null
                              ? apiInfo.data.sections[section].questions[
                                questionNumber
                              ].instruction
                              : checkLanguage(2)
                          ),
                        }}
                      ></Typography>

                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "INFO" && (
                          <Box>
                            <audio
                              style={{ display: "none" }}
                              controls
                              src={recordedBlob}
                            />
                          </Box>
                        )}
                    </Box>
                    <Box className="options-section">
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "SA" ? (
                        <hr></hr>
                      ) : null}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "MA" ? (
                        <hr></hr>
                      ) : null}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "OE" ? (
                        <TextareaAutosize
                          key={
                            apiInfo.data.sections[section].questions[
                              questionNumber
                            ].id
                          }
                          type="text"
                          style={{ top: "3px" }}
                          id={
                            apiInfo.data.sections[section].questions[
                              questionNumber
                            ].id
                          }
                          defaultValue={ifValue()}
                          maxLength={
                            apiInfo.data.sections[section].questions[
                              questionNumber
                            ].maximum == 0
                              ? null
                              : apiInfo.data.sections[section].questions[
                                questionNumber
                              ].maximum
                          }
                        ></TextareaAutosize>
                      ) : null}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "INT" ? (
                        <input
                          key={questionNumber}
                          defaultValue={ifValue()}
                          placeholder="Number"
                          type="number"
                          id="input-int"
                          onKeyDown={(e) => {
                            if (["e", "E", "+", "-",'r'].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          disabled={inputDisabled[questionNumber]}
                          data-id={
                            apiInfo.data.sections[section].questions[
                              questionNumber
                            ].id
                          }
                          max={2}
                        ></input>
                      ) : null}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "DEC" ? (
                        <input
                          key={
                            apiInfo.data.sections[section].questions[
                              questionNumber
                            ].id
                          }
                          defaultValue={ifValue()}
                          placeholder="Number"
                          type="number"
                          step="0.001"
                        ></input>
                      ) : null}

                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "CE" ? (
                        <Box>
                          <Button
                            variant="contained"
                            type="Button"
                            sx={{ backgroundColor: "#607D8B" }}
                            className="reset-Button"
                            onClick={() => resetSelection()}
                          >
                            Reset Selection{" "}
                          </Button>
                        </Box>
                      ) : null}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "ECE" ? (
                        <Box>
                          <Button
                            sx={{ backgroundColor: "#607D8B" }}
                            variant="contained"
                            type="Button"
                            className="reset-Button"
                            onClick={() => resetColorsOnEmojiText()}
                          >
                            Reset Selection{" "}
                          </Button>
                        </Box>
                      ) : null}
                      {rotatedOptionsMemoized?.map((answerOption, index) =>
                      // Single answer questions
                      {
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "SA"
                        ) {
                          var showOption = true;
                          if (answerOption.condition !== null) {
                            showOption = evaluateExpression(
                              answerOption.condition,
                              finalObject
                            );
                            if (showOption === false) {
                              ++decreasedOptionsSA;
                            }
                          }
                          if (showOption === true) {
                            return (
                              <Box>
                                <FormLabel
                                  sx={{ color: "text_col.main" }}
                                  key={JSON.stringify(questionNumber)}
                                >
                                  <input
                                    key={JSON.stringify(questionNumber)}
                                    defaultChecked={ifChecked(
                                      null,
                                      answerOption.value
                                    )}
                                    id={answerOption.value}
                                    data-id={answerOption.id}
                                    data-name= {answerOption.hasOther ? "other" : ""}
                                    data-text={replaceExpression(
                                      apiInfo,
                                      section,
                                      finalObject,
                                      replaceExpression(
                                        apiInfo,
                                        section,
                                        finalObject,
                                        checkLanguage(3, answerOption.id) ==
                                          null
                                          ? rotatedOptionsMemoized[index].text
                                          : checkLanguage(3, answerOption.id)
                                      )
                                    )}
                                    name={"abc" + questionNumber}
                                    type="radio"
                                    tabIndex={answerOption.id}
                                    onClick={() => {
                                      if (answerOption.hasOther) {
                                        changeQuestions(0, true);
                                        return showInputSA(
                                          answerOption.value * 2
                                        );
                                      }

                                      changeQuestions(0, true);
                                      return checkInputs();
                                    }}
                                  ></input>
                                  &nbsp;
                                  {checkLanguage(3, answerOption.id) ==
                                    null ? (
                                    <Typography
                                      style={{ display: "inline" }}
                                      dangerouslySetInnerHTML={{
                                        __html: replaceExpression(
                                          apiInfo,
                                          section,
                                          finalObject,
                                          replaceExpression(
                                            apiInfo,
                                            section,
                                            finalObject,
                                            checkLanguage(
                                              3,
                                              answerOption.id
                                            ) == null
                                              ? answerOption.text
                                              : checkLanguage(
                                                3,
                                                answerOption.id
                                              )
                                          )
                                        ),
                                      }}
                                    ></Typography>
                                  ) : (
                                    checkLanguage(3, answerOption.id)
                                  )}
                                </FormLabel>

                                <Box className="add-input" id="add-input">
                                  {answerOption.hasOther === true ? (
                                    <input
                                      key={JSON.stringify(questionNumber)}
                                      defaultValue={ifValue(
                                        answerOption.value * 2
                                      )}
                                      type="text"
                                      placeholder="Altele..."
                                      id={answerOption.value * 2}
                                      style={{ display: "none" }}
                                    ></input>
                                  ) : null}
                                </Box>
                              </Box>
                            );
                            showInputSA(answerOption.value * 2);
                          }
                          // Multiple answer questions
                        }

                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "MA"
                        ) {
                          var showOption = true;
                          if (answerOption.condition !== null) {
                            showOption = evaluateExpression(
                              answerOption.condition,
                              finalObject
                            );
                            if (showOption === false) {
                              ++decreasedOptionsMA;
                            }
                          }

                          if (showOption === true) {
                            return (
                              <Box>
                                <input
                                  key={Math.random()}
                                  checked={ifChecked(
                                    null,
                                    answerOption.value
                                  )}
                                  data-id={answerOption.id}
                                  data-name={
                                    answerOption.isExclusive
                                      ? "exclusive"
                                      : ""
                                  }
                                  id={answerOption.value}
                                  type="checkbox"
                                  onClick={() => {
                                    if (answerOption.hasOther) {
                                      showInput(
                                        answerOption.value * 2,
                                        document.getElementById(
                                          answerOption.value * 2
                                        ).checked
                                      );
                                    }
                                    if (answerOption.isExclusive) {
                                      disableInputs(answerOption.value);
                                    } else {
                                      disableInputs(
                                        answerOption.value,
                                        "condition"
                                      );
                                    }
                                    changeQuestions(0, true);
                                  }}
                                  defaultChecked={ifChecked(
                                    null,
                                    answerOption.value
                                  )}
                                ></input>
                                <StyledLabel
                                  htmlFor={answerOption.value}
                                  dangerouslySetInnerHTML={{
                                    __html: replaceExpression(
                                      apiInfo,
                                      section,
                                      finalObject,
                                      replaceExpression(
                                        apiInfo,
                                        section,
                                        finalObject,
                                        checkLanguage(3, answerOption.id) == null
                                          ? answerOption.text
                                          : checkLanguage(3, answerOption.id)
                                      )
                                    ),
                                  }}
                                />

                                {answerOption.hasOther === true ? (
                                  <input
                                    key={JSON.stringify(questionNumber)}
                                    defaultValue={ifValue(
                                      answerOption.value * 2
                                    )}
                                    type="text"
                                    data-date={new Date().toISOString()}
                                    placeholder="Altele..."
                                    id={answerOption.value * 2}
                                    
                                    style={{ display: "none" }}
                                  ></input>
                                ) : null}
                              </Box>
                            );
                          }
                        }
                        // Open ended questions
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "OE"
                        ) {
                          return (
                            <Box>
                              <input
                                key={JSON.stringify(questionNumber)}
                                defaultChecked={ifChecked(
                                  null,
                                  answerOption.value
                                )}
                                data-id={answerOption.id}
                                id={answerOption.value}
                                name={answerOption.value}
                                type="checkbox"
                              // onClick={changeQuestions(0,true)}
                              ></input>
                              <FormLabel
                                for={answerOption.value}
                                className="options-labels"
                                sx={{ color: "text_col.main" }}
                              >
                                {checkLanguage(3, answerOption.id) == null ? (
                                  <Typography
                                    style={{ display: "inline" }}
                                    dangerouslySetInnerHTML={{
                                      __html: replaceExpression(
                                        apiInfo,
                                        section,
                                        finalObject,
                                        answerOption.text
                                      ),
                                    }}
                                  ></Typography>
                                ) : (
                                  checkLanguage(3, answerOption.id)
                                )}
                              </FormLabel>
                            </Box>
                          );
                        }
                        // Integer questions with options
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "INT"
                        ) {
                          return (
                            <Box>
                              <input
                                onClick={() => {
                                  if (answerOption.value == "98") {
                                    // const updatedDisabled = [...inputDisabled];
                                    // updatedDisabled[questionNumber] = !updatedDisabled[questionNumber];
                                    // setInputDisabled(updatedDisabled);
                                  }
                                }}
                                key={JSON.stringify(questionNumber)}
                                defaultChecked={ifChecked(
                                  null,
                                  answerOption.value
                                )}
                                data-id={answerOption.id}
                                id={answerOption.value}
                                type="checkbox"
                              ></input>
                              <FormLabel
                                for={answerOption.value}
                                className="options-labels"
                                sx={{ color: "text_col.main" }}
                              >
                                {checkLanguage(3, answerOption.id) == null ? (
                                  <Typography
                                    style={{ display: "inline" }}
                                    dangerouslySetInnerHTML={{
                                      __html: replaceExpression(
                                        apiInfo,
                                        section,
                                        finalObject,
                                        answerOption.text
                                      ),
                                    }}
                                  ></Typography>
                                ) : (
                                  checkLanguage(3, answerOption.id)
                                )}
                              </FormLabel>
                            </Box>
                          );
                        }
                        // For each option there will be an input
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "OEPR"
                        ) {
                          var showOption = true;
                          if (answerOption.condition !== null) {
                            showOption = evaluateExpression(
                              answerOption.condition,
                              finalObject
                            );
                            if (showOption === false || showOption == null) {
                            }
                          }
                          if (showOption === true) {
                            return (
                              <Box className="options-list">
                                <FormLabel
                                  sx={{ color: "text_col.main" }}
                                  style={{
                                    fontSize: "15px",
                                    marginBottom: "8px",
                                  }}
                                  for={answerOption.value}
                                  className="options-labels"
                                >
                                  {" "}
                                  {checkLanguage(3, answerOption.id) ==
                                    null ? (
                                    <Typography
                                      style={{ display: "inline" }}
                                      dangerouslySetInnerHTML={{
                                        __html: replaceExpression(
                                          apiInfo,
                                          section,
                                          finalObject,
                                          answerOption.text
                                        ),
                                      }}
                                    ></Typography>
                                  ) : (
                                    checkLanguage(3, answerOption.id)
                                  )}
                                  <input
                                    key={JSON.stringify(
                                      questionNumber + answerOption.value
                                    )}
                                    defaultValue={ifValue(answerOption.value)}
                                    data-id={answerOption.id}
                                    id={answerOption.value}
                                    name={answerOption.value}
                                    type="text"
                                    maxLength={
                                      apiInfo.data.sections[section]
                                        .questions[questionNumber].maximum ==
                                        0
                                        ? null
                                        : apiInfo.data.sections[section]
                                          .questions[questionNumber].maximum
                                    }
                                  ></input>
                                </FormLabel>
                                <br></br>

                                {answerOption.isExclusive == true ? (
                                  <Box style={{ display: "inline" }}>
                                    <input
                                      key={JSON.stringify(questionNumber)}
                                      id={answerOption.value + " nustiu"}
                                      onClick={() => {
                                        disableInput(answerOption.value);
                                      }}
                                      defaultChecked={ifChecked(
                                        answerOption.value,
                                        answerOption.value
                                      )}
                                      type="checkbox"
                                    ></input>{" "}
                                    <label
                                      class="dontknow"
                                      for={answerOption.value + " nustiu"}
                                    >
                                      {language === 0
                                        ? " NȘ/NR"
                                        : language === 1
                                          ? " Nu stiu"
                                          : " Я не знаю"}
                                    </label>
                                  </Box>
                                ) : null}
                              </Box>
                            );
                          }
                        }
                        // Integer per row for each option
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "INTPR"
                        ) {
                          var showOption = true;
                          if (answerOption.condition !== null) {
                            showOption = evaluateExpression(
                              answerOption.condition,
                              finalObject
                            );
                            if (showOption === false || showOption == null) {
                            }
                          }
                          if (showOption === true) {
                            return (
                              <Box>
                                <FormLabel
                                  for={answerOption.value}
                                  className="options-labels"
                                  sx={{ color: "text_col.main" }}
                                >
                                  {checkLanguage(3, answerOption.id) ==
                                    null ? (
                                    <Typography
                                      style={{ display: "inline" }}
                                      dangerouslySetInnerHTML={{
                                        __html: replaceExpression(
                                          apiInfo,
                                          section,
                                          finalObject,
                                          answerOption.text
                                        ),
                                      }}
                                    ></Typography>
                                  ) : (
                                    checkLanguage(3, answerOption.id)
                                  )}
                                  <br />
                                  <input
                                    key={JSON.stringify(questionNumber)}
                                    defaultValue={ifValue(answerOption.value)}
                                    data-id={answerOption.id}
                                    id={answerOption.value}
                                    name={answerOption.value}
                                    type="number"
                                  ></input>
                                  {answerOption.isExclusive == true ? (
                                    <Box style={{ display: "inline" }}>
                                      <input
                                        key={JSON.stringify(questionNumber)}
                                        id={answerOption.value + " nustiu"}
                                        onClick={() => {
                                          disableInput(answerOption.value);
                                        }}
                                        type="checkbox"
                                        defaultChecked={ifChecked(
                                          answerOption.value,
                                          answerOption.value
                                        )}
                                      ></input>
                                      <label
                                        class="dontknow"
                                        for={answerOption.value + " nustiu"}
                                      >
                                        {language === 0
                                          ? " NȘ/NR"
                                          : language === 1
                                            ? " Nu stiu"
                                            : " Я не знаю"}
                                      </label>
                                    </Box>
                                  ) : null}
                                </FormLabel>
                              </Box>
                            );
                          }
                        }
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "DECPR"
                        ) {
                          return (
                            <FormLabel
                              className="options-labels"
                              sx={{ color: "text_col.main" }}
                              for={answerOption.value}
                            >
                              {checkLanguage(3, answerOption.id) == null ? (
                                <Typography
                                  style={{ display: "inline" }}
                                  dangerouslySetInnerHTML={{
                                    __html: replaceExpression(
                                      apiInfo,
                                      section,
                                      finalObject,
                                      answerOption.text
                                    ),
                                  }}
                                ></Typography>
                              ) : (
                                checkLanguage(3, answerOption.id)
                              )}
                              <br />
                              <input
                                key={JSON.stringify(questionNumber)}
                                defaultValue={ifValue(answerOption.value)}
                                data-id={answerOption.id}
                                name={answerOption.value}
                                id={answerOption.value}
                                type="number"
                                step="0.001"
                              ></input>
                              {answerOption.isExclusive == true ? (
                                <Box style={{ display: "inline" }}>
                                  <input
                                    key={JSON.stringify(questionNumber)}
                                    id={answerOption.value + " nustiu"}
                                    onClick={() => {
                                      disableInput(answerOption.value);
                                    }}
                                    defaultChecked={ifChecked(
                                      answerOption.value,
                                      answerOption.value
                                    )}
                                    type="checkbox"
                                  ></input>
                                  <label
                                    class="dontknow"
                                    for={answerOption.value + " nustiu"}
                                  >
                                    {language === 0
                                      ? " NȘ/NR"
                                      : language === 1
                                        ? " Nu stiu"
                                        : " Я не знаю"}
                                  </label>
                                </Box>
                              ) : null}
                            </FormLabel>
                          );
                        }
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "CE"
                        ) {
                          return (
                            <Box
                              data-id={answerOption.id}
                              id={answerOption.order}
                              style={{
                                display: "inline",
                                fontWeight: fillWords(answerOption.order),

                              }}

                              onClick={() => {
                                clickOrder(answerOption.order);
                              }}
                            >
                              {checkLanguage(3, answerOption.id) == null ? (
                                <Typography
                                  style={{ display: "inline", cursor: "pointer" }}
                                  dangerouslySetInnerHTML={{
                                    __html: replaceExpression(
                                      apiInfo,
                                      section,
                                      finalObject,
                                      answerOption.text
                                    ),
                                  }}
                                ></Typography>
                              ) : (
                                checkLanguage(3, answerOption.id)
                              )}{" "}
                            </Box>
                          );
                        }
                        if (
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].type === "ECE"
                        ) {
                          return (
                            <Box
                              style={{ display: "inline" }}
                              id={"text-emojis" + index}
                            >
                              <Button
                                style={{
                                  color: fillButtons(
                                    answerOption.value,
                                    index
                                  ),
                                }}
                                data-id={answerOption.id}
                                id={"Popover1" + index}
                                type="Button"
                                name={answerOption.value}
                                className="popover-buttons"
                              >
                                {checkLanguage(3, answerOption.id) == null ? (
                                  <Typography
                                    style={{ display: "inline" }}
                                    dangerouslySetInnerHTML={{
                                      __html: replaceExpression(
                                        apiInfo,
                                        section,
                                        finalObject,
                                        answerOption.text
                                      ),
                                    }}
                                  ></Typography>
                                ) : (
                                  checkLanguage(3, answerOption.id)
                                )}
                              </Button>
                              <UncontrolledPopover
                                trigger="focus"
                                placement="bottom"
                                target={"Popover1" + index}
                              >
                                {/* <PopoverHeader>Popover Title</PopoverHeader> */}

                                <PopoverBody>
                                  <EmojiSmile
                                    fontSize="34px"
                                    className="emoji-smile"
                                    onClick={() =>
                                      changeTextColorToGreen(
                                        "Popover1" + index,
                                        answerOption.value,
                                        "smile"
                                      )
                                    }
                                  />
                                  <EmojiNeutral
                                    fontSize="34px"
                                    className="emoji-neutral"
                                    onClick={() =>
                                      changeTextColorToOrange(
                                        "Popover1" + index,
                                        answerOption.value,
                                        "neutral"
                                      )
                                    }
                                  />
                                  <EmojiSmileUpsideDown
                                    fontSize="34px"
                                    className="emoji-sad"
                                    onClick={() =>
                                      changeTextColorToRed(
                                        "Popover1" + index,
                                        answerOption.value,
                                        "sad"
                                      )
                                    }
                                  />
                                  <Trash
                                    fontSize="34px"
                                    className="emoji-trash"
                                    onClick={() =>
                                      changeToDefaultColor(
                                        "Popover1" + index,
                                        answerOption.value,
                                        "default"
                                      )
                                    }
                                  />
                                </PopoverBody>
                              </UncontrolledPopover>
                            </Box>
                          );
                        }
                      }
                      )}

                      {/* Single answer per row questions */}
                      {(apiInfo.data.sections[section].questions[questionNumber]
                        .type === "SAPR" ||
                        apiInfo.data.sections[section].questions[questionNumber]
                          .type === "MAPR") &&
                        width > 1000 ? (

                        <div className="sapr-table">
                          <table className="table" id="myTable">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                {rotatedOptionsMemoized.map(
                                  (answerOption, index) => {
                                    if (
                                      answerOption.level === 2 &&
                                      answerOption.condition !== "false"
                                    ) {
                                      var showOption = true;
                                      if (
                                        answerOption.condition != null &&
                                        answerOption.condition !== "false"
                                      ) {
                                        var showOption = evaluateExpression(
                                          answerOption.condition,
                                          finalObject
                                        );

                                        if (showOption == false) {
                                          // ++decreasedOptionsSAPR;
                                        }

                                      }
                                      if (answerOption.text == "Nici unul") {
                                        showOption = false;
                                      }
                                      if (showOption == true) {
                                        return (
                                          <th
                                            scope="col"
                                            key={JSON.stringify(
                                              answerOption.id
                                            )}
                                            id={answerOption.order}
                                          >
                                            {checkLanguage(
                                              3,
                                              answerOption.id
                                            ) == null ? (
                                              <Typography
                                                style={{ display: "inline" }}
                                                dangerouslySetInnerHTML={{
                                                  __html: replaceExpression(
                                                    apiInfo,
                                                    section,
                                                    finalObject,
                                                    replaceExpression(
                                                      apiInfo,
                                                      section,
                                                      finalObject,
                                                      checkLanguage(
                                                        3,
                                                        answerOption.id
                                                      ) == null
                                                        ? answerOption.text
                                                        : checkLanguage(
                                                          3,
                                                          answerOption.id
                                                        )
                                                    )
                                                  ),
                                                }}
                                              ></Typography>
                                            ) : (
                                              checkLanguage(3, answerOption.id)
                                            )}
                                          </th>
                                        );
                                      }
                                    }
                                  }
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {rotatedOptionsMemoized.map(
                                (firstanswerOption, index) => {
                                  if (
                                    firstanswerOption.level === 1 &&
                                    firstanswerOption.condition !== "false"
                                  ) {

                                    var showOption = true;
                                    var lineCOndition =
                                      firstanswerOption.condition;
                                    if (firstanswerOption.condition != null) {
                                      var showOption = evaluateExpression(
                                        firstanswerOption.condition,
                                        finalObject
                                      );

                                      if (showOption == false) {
                                        ++decreasedOptionsSAPR;

                                      }
                                    }
                                    if (showOption == true) {
                                      return (
                                        <tr
                                         className="panel-group"
                                          key={JSON.stringify(
                                            firstanswerOption.id
                                          )}
                                        >
                                          <th>
                                            <Typography
                                              dangerouslySetInnerHTML={{
                                                __html: replaceExpression(
                                                  apiInfo,
                                                  section,
                                                  finalObject,
                                                  replaceExpression(
                                                    apiInfo,
                                                    section,
                                                    finalObject,
                                                    checkLanguage(
                                                      3,
                                                      firstanswerOption.id
                                                    ) == null
                                                      ? firstanswerOption.text
                                                      : checkLanguage(
                                                        3,
                                                        firstanswerOption.id
                                                      )
                                                  )
                                                ),
                                              }}
                                            />
                                            <img
                                              alt=""
                                              style={{
                                                width: "100%",
                                                height: "auto",
                                              }}
                                              src={
                                                // if imagesUrl[firstanswerOption.src] doesnt contain digicode add  https://vision.digicode.ro/
                                                imagesUrl[firstanswerOption.src]
                                              }
                                            />
                                          </th>
                                          {rotatedOptionsMemoized.map(
                                            (answerOption, index1) => {
                                              if (
                                                answerOption.level === 2 &&
                                                answerOption.condition !==
                                                "false"
                                              ) {
                                                var showOption = true;
                                                if (
                                                  answerOption.condition != null
                                                ) {
                                                  var showOption =
                                                    evaluateExpression(
                                                      answerOption.condition,
                                                      finalObject
                                                    );

                                                  if (showOption == false) {
                                                    ++decreasedOptionsSAPR;
                                                  }
                                                }
                                                if (
                                                  answerOption.text ==
                                                  "Nici unul"
                                                ) {
                                                  showOption = false;
                                                }
                                                if (showOption == true) {
                                                  return (
                                                    <td>
                                                      <input
                                                        key={JSON.stringify(
                                                          questionNumber
                                                        )}
                                                        defaultChecked={ifChecked(
                                                          firstanswerOption.value,
                                                          answerOption.value
                                                        )}
                                                        data-id={
                                                          answerOption.id
                                                        }
                                                        id={answerOption.value}
                                                        name={
                                                          firstanswerOption.value
                                                        }
                                                        type={
                                                          apiInfo.data.sections[
                                                            section
                                                          ].questions[
                                                            questionNumber
                                                          ].type === "MAPR"
                                                            ? "checkbox"
                                                            : "radio"
                                                        }
                                                      />
                                                    </td>
                                                  );
                                                }
                                              }
                                            }
                                          )}
                                        </tr>
                                      );
                                    }
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : null}

                      {/* Single answer per row / Multiple answer per row */}
                      {(apiInfo.data.sections[section].questions[questionNumber]
                        .type === "SAPR" ||
                        apiInfo.data.sections[section].questions[questionNumber]
                          .type === "MAPR") &&
                        width < 1001 ? (
                        <div className="container-fluid">
                          {rotatedOptionsMemoized.map(
                            (firstanswerOption, index1) => {
                              if (firstanswerOption.level === 1) {
                                var showOption = true;

                                if (firstanswerOption.condition !== null) {
                                  showOption = evaluateExpression(
                                    firstanswerOption.condition,
                                    finalObject
                                  );
                                  if (showOption === false) {
                                    ++decreasedOptionsSAPR;
                                  }
                                }
                                if (showOption === true) {
                                  return (
                                    <div
                                      className="panel-group"
                                      key={JSON.stringify(
                                        firstanswerOption.id + questionNumber
                                      )}
                                    >
                                      <div className="panel-heading effect6">
                                        <h4 className="panel-title">
                                          <p
                                            data-toggle="collapse"
                                            className="panel-texts"
                                            href={"#collapse" + index1}
                                            style={{ fontSize: "auto" }}
                                            dangerouslySetInnerHTML={{
                                              __html: replaceExpression(
                                                apiInfo,
                                                section,
                                                finalObject,
                                                replaceExpression(
                                                  apiInfo,
                                                  section,
                                                  finalObject,
                                                  checkLanguage(
                                                    3,
                                                    firstanswerOption.id
                                                  ) == null
                                                    ? firstanswerOption.text
                                                    : checkLanguage(
                                                      3,
                                                      firstanswerOption.id
                                                    )
                                                )
                                              ),
                                            }}
                                          >
                                            {/* {evaluateExpression(
                                            checkLanguage(
                                              3,
                                              firstanswerOption.id
                                            ) == null
                                              ? firstanswerOption.text.replace(
                                                  /<([^\/][^\s]*?).*?(?:\/>|>(?:.|\n)*?<\/\1>)/g,
                                                  ``
                                                )
                                              : checkLanguage(
                                                  3,
                                                  firstanswerOption.id
                                                ),
                                            finalObject,
                                            false
                                          )} */}
                                          </p>
                                        </h4>
                                      </div>
                                      <div
                                        id={"collapse" + index1}
                                        className="panel-collapse collapse"
                                      >
                                        <img
                                          href={"#collapse" + index1}
                                          data-toggle="collapse"
                                          src={imagesUrl[firstanswerOption.src]}
                                          style={{
                                            display: imagesUrl[
                                              firstanswerOption.src
                                            ]
                                              ? "block"
                                              : "none",
                                          }}
                                        ></img>

                                        <ul
                                          className="list-group"
                                          key={questionNumber}
                                        >
                                          {rotatedOptionsMemoized.map(
                                            (answerOption, index) => {
                                              if (
                                                answerOption.level === 2 &&
                                                answerOption.condition !==
                                                "false"
                                              ) {
                                                return (
                                                  <li
                                                    className="options-list"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "baseline",
                                                    }}
                                                    key={index}
                                                  >
                                                    <StyledLabel
                                                      className="options-labels"
                                                      sx={{
                                                        color: "text_col.main",
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      <input
                                                        key={index}
                                                        defaultChecked={ifChecked(
                                                          firstanswerOption.value,
                                                          answerOption.value
                                                        )}
                                                        data-id={
                                                          answerOption.id
                                                        }
                                                        name={
                                                          firstanswerOption.value
                                                        }
                                                        type={
                                                          apiInfo.data.sections[
                                                            section
                                                          ].questions[
                                                            questionNumber
                                                          ].type === "MAPR"
                                                            ? "checkbox"
                                                            : "radio"
                                                        }
                                                        id={answerOption.value} // Optional: You can remove the id if it's not used elsewhere.
                                                      ></input>
                                                      <span
                                                        style={{
                                                          paddingLeft: "10px",
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            replaceExpression(
                                                              apiInfo,
                                                              section,
                                                              finalObject,
                                                              replaceExpression(
                                                                apiInfo,
                                                                section,
                                                                finalObject,
                                                                checkLanguage(
                                                                  3,
                                                                  answerOption.id
                                                                ) == null
                                                                  ? answerOption.text
                                                                  : checkLanguage(
                                                                    3,
                                                                    answerOption.id
                                                                  )
                                                              )
                                                            ),
                                                        }}
                                                      />
                                                    </StyledLabel>
                                                  </li>
                                                );
                                              }
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            }
                          )}
                        </div>
                      ) : null}

                      {/* Search question */}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "SEA" ? (
                        <Box>
                          <Select
                            options={getOptions()}
                            placeholder={ifValue()}
                            defaultValue={returnSea()}
                            name="getSelect"
                          />

                          {apiInfo.data.sections[section].questions[
                            questionNumber
                          ].hasOther === false ? (
                            <input
                              key={questionNumber}
                              type="text"
                              placeholder="Altele..."
                              id={
                                apiInfo.data.sections[section].questions[
                                  questionNumber
                                ].type
                              }
                              style={{ display: "none" }}
                            ></input>
                          ) : null}
                        </Box>
                      ) : null}
                      {/* Dropdowns with selects and options */}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "SAPRC" && width < 1001 ? (
                        <Box className="container-fluid">
                          {rotatedOptionsMemoized.options.map(
                            (firstanswerOption, index) => {
                              if (firstanswerOption.level === 1) {
                                return (
                                  <Box className="panel-group">
                                    <Box className="panel-heading effect6  ">
                                      <h4 className="panel-title ">
                                        <p
                                          data-toggle="collapse"
                                          className="panel-texts"
                                          href={"#collapse" + index}
                                        >
                                          {checkLanguage(
                                            3,
                                            firstanswerOption.id
                                          ) == null
                                            ? firstanswerOption.text
                                            : checkLanguage(
                                              3,
                                              firstanswerOption.id
                                            )}
                                        </p>
                                      </h4>
                                    </Box>
                                    <Box
                                      id={"collapse" + index}
                                      className="panel-collapse collapse"
                                    >
                                      <ul className="list-group">
                                        {rotatedOptionsMemoized.map(
                                          (secondAnswerOption, index1) => {
                                            if (secondAnswerOption.level == 2) {
                                              var showOption = true;
                                              if (
                                                secondAnswerOption.condition !==
                                                null
                                              ) {
                                                showOption = evaluateExpression(
                                                  secondAnswerOption.condition,
                                                  finalObject
                                                );
                                                if (showOption === false) {
                                                  ++decreasedOptionsSAPRC;
                                                }
                                              }
                                              if (showOption === true) {
                                                return (
                                                  <Box>
                                                    <FormLabel
                                                      className="select-FormLabel"
                                                      sx={{
                                                        color: "text_col.main",
                                                      }}
                                                    >
                                                      {checkLanguage(
                                                        3,
                                                        secondAnswerOption.id
                                                      ) == null
                                                        ? secondAnswerOption.text
                                                        : checkLanguage(
                                                          3,
                                                          secondAnswerOption.id
                                                        )}
                                                    </FormLabel>

                                                    <select
                                                      key={JSON.stringify(
                                                        questionNumber
                                                      )}
                                                      defaultValue={test(
                                                        firstanswerOption.value,
                                                        secondAnswerOption.value
                                                      )}
                                                      id={
                                                        firstanswerOption.value
                                                      }
                                                      name={
                                                        secondAnswerOption.value
                                                      }
                                                      data-id={
                                                        secondAnswerOption.id
                                                      }
                                                      className="select-drop"
                                                    >
                                                      <option
                                                        value=""
                                                        disabled
                                                        selected
                                                        hidden
                                                      >
                                                        Select
                                                      </option>
                                                      {rotatedOptionsMemoized.map(
                                                        (
                                                          answerOption,
                                                          index
                                                        ) => {
                                                          if (
                                                            answerOption.level ==
                                                            3
                                                          ) {
                                                            return (
                                                              <option
                                                                selected={false}
                                                                id={
                                                                  answerOption.value
                                                                }
                                                                value={
                                                                  answerOption.value
                                                                }
                                                                data-id={
                                                                  answerOption.id
                                                                }
                                                              >
                                                                {checkLanguage(
                                                                  3,
                                                                  answerOption.id
                                                                ) == null
                                                                  ? answerOption.text
                                                                  : checkLanguage(
                                                                    3,
                                                                    answerOption.id
                                                                  )}
                                                              </option>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </select>
                                                  </Box>
                                                );
                                              }
                                            }
                                          }
                                        )}
                                      </ul>
                                    </Box>
                                  </Box>
                                );
                              }
                            }
                          )}
                        </Box>
                      ) : null}

                      {/* Table with selects and options */}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "SAPRC" && width > 1000 ? (
                        <Box className="sapr-table">
                          <table className="table">
                            {/* <thead> */}
                            {/* <tr> */}
                            <th scope="col"></th>
                            {rotatedOptionsMemoized.map(
                              (first1answerOption, index) => {
                                if (first1answerOption.level === 2) {
                                  var showOption = true;
                                  if (first1answerOption.condition !== null) {
                                    showOption = evaluateExpression(
                                      first1answerOption.condition,
                                      finalObject
                                    );
                                  }
                                  if (showOption === true) {
                                    return (
                                      <th scope="col">
                                        {replaceExpression(
                                          apiInfo,
                                          section,
                                          finalObject,
                                          checkLanguage(
                                            3,
                                            first1answerOption.id
                                          ) == null
                                            ? first1answerOption.text
                                            : checkLanguage(
                                              3,
                                              first1answerOption.id
                                            )
                                        )}
                                      </th>
                                    );
                                  }
                                }
                              }
                            )}

                            {/* </tr> */}
                            {/* </thead> */}
                            <tbody>
                              {rotatedOptionsMemoized.map(
                                (firstanswerOption, index) => {
                                  if (firstanswerOption.level === 1) {
                                    return (
                                      <tr>
                                        <th scope="row">
                                          {replaceExpression(
                                            apiInfo,
                                            section,
                                            finalObject,
                                            checkLanguage(
                                              3,
                                              firstanswerOption.id
                                            ) == null
                                              ? firstanswerOption.text
                                              : checkLanguage(
                                                3,
                                                firstanswerOption.id
                                              )
                                          )}
                                        </th>
                                        {rotatedOptionsMemoized.map(
                                          (answerOptionSelect, index) => {
                                            if (
                                              answerOptionSelect.level === 2
                                            ) {
                                              var showOption = true;
                                              if (
                                                answerOptionSelect.condition !==
                                                null
                                              ) {
                                                showOption = evaluateExpression(
                                                  answerOptionSelect.condition,
                                                  finalObject
                                                );
                                              }
                                              if (showOption === true) {
                                                return (
                                                  <td>
                                                    <select
                                                      key={JSON.stringify(
                                                        questionNumber
                                                      )}
                                                      id={
                                                        firstanswerOption.value
                                                      }
                                                      name={
                                                        answerOptionSelect.value
                                                      }
                                                      data-id={
                                                        answerOptionSelect.id
                                                      }
                                                      defaultValue={test(
                                                        firstanswerOption.value,
                                                        answerOptionSelect.value
                                                      )}
                                                    >
                                                      <option
                                                        value=""
                                                        disabled
                                                        hidden
                                                        selected
                                                      >
                                                        Select
                                                      </option>
                                                      {rotatedOptionsMemoized.map(
                                                        (
                                                          answerOption,
                                                          index
                                                        ) => {
                                                          if (
                                                            answerOption.level ==
                                                            3
                                                          ) {
                                                            return (
                                                              <option
                                                                value={
                                                                  answerOption.value
                                                                }
                                                                data-id={
                                                                  answerOption.id
                                                                }
                                                              >
                                                                {
                                                                  answerOption.text
                                                                }
                                                              </option>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </select>
                                                  </td>
                                                );
                                              }
                                            }
                                          }
                                        )}
                                      </tr>
                                    );
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </Box>
                      ) : null}

                      {/* Like SAPR but radio inputs are in sync */}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "RANK" && width > 1000 ? (
                        <Box className="sapr-table">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                {rotatedOptionsMemoized.map(
                                  (answerOption, index) => {
                                    if (answerOption.level === 2) {
                                      return (
                                        <th scope="col">
                                          {checkLanguage(3, answerOption.id) ==
                                            null
                                            ? answerOption.text
                                            : checkLanguage(3, answerOption.id)}
                                        </th>
                                      );
                                    }
                                  }
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {rotatedOptionsMemoized.map(
                                (firstanswerOption, index) => {
                                  if (firstanswerOption.level === 1) {
                                    return (
                                      <tr>
                                        <th scope="row">
                                          {checkLanguage(
                                            3,
                                            firstanswerOption.id
                                          ) == null
                                            ? firstanswerOption.text
                                            : checkLanguage(
                                              3,
                                              firstanswerOption.id
                                            )}
                                        </th>
                                        {rotatedOptionsMemoized.map(
                                          (answerOption, index1) => {
                                            if (answerOption.level === 2) {
                                              return (
                                                <td>
                                                  <input
                                                    key={JSON.stringify(
                                                      questionNumber
                                                    )}
                                                    data-id={answerOption.id}
                                                    name={
                                                      firstanswerOption.value
                                                    }
                                                    defaultChecked={ifChecked(
                                                      firstanswerOption.value,
                                                      answerOption.value
                                                    )}
                                                    id={answerOption.value}
                                                    type={
                                                      apiInfo.data.sections[
                                                        section
                                                      ].questions[
                                                        questionNumber
                                                      ].type === "MAPR"
                                                        ? "checkbox"
                                                        : "radio"
                                                    }
                                                  />
                                                </td>
                                              );
                                            }
                                          }
                                        )}
                                      </tr>
                                    );
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </Box>
                      ) : null}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "RANK" && width < 1000 ? (
                        <Box className="container-fluid">
                          {rotatedOptionsMemoized.map(
                            (first1answerOption, index) => {
                              if (first1answerOption.level === 1) {
                                return (
                                  <Box className="panel-group">
                                    <Box className="panel-heading effect6  ">
                                      <h4 className="panel-title ">
                                        <p
                                          data-toggle="collapse"
                                          className="panel-texts"
                                          href={"#collapse" + index}
                                        >
                                          {checkLanguage(
                                            3,
                                            first1answerOption.id
                                          ) == null
                                            ? first1answerOption.text
                                            : checkLanguage(
                                              3,
                                              first1answerOption.id
                                            )}
                                        </p>
                                      </h4>
                                    </Box>
                                    <Box
                                      id={"collapse" + index}
                                      className="panel-collapse collapse"
                                    >
                                      <ul className="list-group">
                                        {rotatedOptionsMemoized.map(
                                          (answerOption, index1) => {
                                            if (answerOption.level === 2) {
                                              return (
                                                <li
                                                  key={answerOption.value}
                                                  className="options-list"
                                                >
                                                  <FormLabel
                                                    className="options-labels"
                                                    sx={{
                                                      color: "text_col.main",
                                                    }}
                                                  >
                                                    <input
                                                      key={JSON.stringify(
                                                        questionNumber
                                                      )}
                                                      defaultChecked={ifChecked(
                                                        first1answerOption.value,
                                                        answerOption.value
                                                      )}
                                                      data-id={answerOption.id}
                                                      name={
                                                        first1answerOption.value
                                                      }
                                                      id={answerOption.value}
                                                      type={
                                                        apiInfo.data.sections[
                                                          section
                                                        ].questions[
                                                          questionNumber
                                                        ].type === "MAPR"
                                                          ? "checkbox"
                                                          : "radio"
                                                      }
                                                    ></input>
                                                    &nbsp;
                                                    {checkLanguage(
                                                      3,
                                                      answerOption.id
                                                    ) == null
                                                      ? answerOption.text
                                                      : checkLanguage(
                                                        3,
                                                        answerOption.id
                                                      )}{" "}
                                                  </FormLabel>
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                    </Box>
                                  </Box>
                                );
                              }
                            }
                          )}
                        </Box>
                      ) : null}

                      {/*SCALE - table with negative-positive radio inputs*/}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "SCALE" ? (
                        <Box className="sapr-table">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col"></th>

                                {rotatedOptionsMemoized.map(
                                  (answerOption, index) => {
                                    if (answerOption.level === 3) {
                                      return (
                                        <th scope="col">
                                          {" "}
                                          {checkLanguage(3, answerOption.id) ==
                                            null
                                            ? answerOption.text
                                            : checkLanguage(3, answerOption.id)}
                                        </th>
                                      );
                                    }
                                  }
                                )}
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {apiInfo.data.sections[section].questions[
                                questionNumber
                              ].options.map((firstanswerOption, index) => {
                                if (firstanswerOption.level === 1) {
                                  return (
                                    <tr>
                                      <th scope="row">
                                        {checkLanguage(
                                          3,
                                          firstanswerOption.id
                                        ) == null
                                          ? firstanswerOption.text
                                          : checkLanguage(
                                            3,
                                            firstanswerOption.id
                                          )}
                                      </th>
                                      {rotatedOptionsMemoized.map(
                                        (answerOption, index1) => {
                                          if (answerOption.level === 3) {
                                            return (
                                              <td>
                                                <input
                                                  // key={JSON.stringify(
                                                  //   questionNumber
                                                  // )}
                                                  defaultChecked={ifChecked(
                                                    firstanswerOption.value,
                                                    answerOption.value
                                                  )}
                                                  data-id={answerOption.id}
                                                  key={answerOption.value}
                                                  id={answerOption.value}
                                                  name={firstanswerOption.value}
                                                  type={"radio"}
                                                />
                                              </td>
                                            );
                                          }
                                        }
                                      )}
                                      {rotatedOptionsMemoized.map(
                                        (anotherAnswerOption, index1) => {
                                          if (
                                            anotherAnswerOption.level === 2 &&
                                            anotherAnswerOption.order ===
                                            firstanswerOption.order
                                          ) {
                                            return (
                                              <td>
                                                {checkLanguage(
                                                  3,
                                                  anotherAnswerOption.id
                                                ) == null
                                                  ? anotherAnswerOption.text
                                                  : checkLanguage(
                                                    3,
                                                    anotherAnswerOption.id
                                                  )}
                                              </td>
                                            );
                                          }
                                        }
                                      )}
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </Box>
                      ) : null}
                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "ARAND"
                        ? getRandomNumber(
                          apiInfo.data.sections[section].questions[
                            questionNumber
                          ].maximum
                        )
                        : null}

                      {apiInfo.data.sections[section].questions[questionNumber]
                        .type === "CITY" ? (
                        <Box>
                          <FormLabel classname="county">Judet</FormLabel>
                          <Select
                            // placeholder={"Type your county"}
                            name={"getSelect1"}
                            isClearable={true}
                            defaultValue={ifValue(null, null, null, "county")}
                            id="city-select"
                            options={countyOptions}
                            onFocus={() => {
                              getOptionsCountys();
                            }}
                            onInputChange={() => {
                              getOptionsCountys();
                            }}
                          ></Select>
                          <FormLabel classname="city">Localitate</FormLabel>
                          <Select
                            placeholder="Type your City"
                            defaultValue={ifValue(null, null, null, "city")}
                            name="getSelect2"
                            id="county-select"
                            options={cityOptions}
                            onFocus={() => {
                              getOptionsCities();
                            }}
                            onInputChange={() => {
                              getOptionsCities();
                            }}
                            onChange={handleCitySelectChange}
                          ></Select>
                          {selectedCity?.label === "Rural" && (
                            <div>
                              <FormLabel>Altele</FormLabel>
                              <input
                                id="city-others"
                                type="text"
                                name="additionalInput"
                              />
                            </div>
                          )}
                        </Box>
                      ) : null}
                      {/* HERE */}

                      {apiInfo?.data?.sections[section]?.questions[questionNumber + 1]?.type === "HM" || apiInfo?.data?.sections[section]?.questions[questionNumber]?.type === "HM" ? (
                        <img
                          src={image}
                          onLoad={handleImageLoad}
                          alt="hidden"
                          style={{ display: "none" }}
                        />
                      ) : null}

                      {apiInfo.data.sections[section].questions[questionNumber].type === "HM" ? (
                        <Box>

                          <FormGroup>
                            {map.areas && imgDimensions.width > 0 && imgDimensions.height > 0 && (
                              <ImageMapper
                                key={apiInfo.data.sections[section].questions[questionNumber].id}
                                className="responsive-image"
                                src={image}
                                onLoad={handleImageLoad}
                                map={map}
                                width={displayWidth}
                                height={displayHeight}
                                onClick={(area) => retainOrderShapes(area.name)}
                                style={{ width: displayWidth, height: displayHeight }}
                              />
                            )}
                            <label className="label-hm">
                              Niciunul
                              <input
                                key={apiInfo.data.sections[section].questions[questionNumber].id}
                                id={apiInfo.data.sections[section].questions[questionNumber].id}
                                type="checkbox"
                                name="hm-checkbox"
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  if (isChecked) {
                                    createMap("resetShapes", isChecked);
                                  }
                                  if (shapeOrder.length > 0) {
                                    setShapeOrder([]);
                                  }
                                }}
                              />
                            </label>
                          </FormGroup>
                        </Box>
                      ) : null}
                    </Box>
                  </form>
                  <Typography
                    id="error"
                    className="alert alert-danger"
                    style={{
                      display: display,
                      marginTop: marginTop,
                      backgroundColor: "#F8D7DA",
                    }}
                  >
                    {textError}
                  </Typography>
                  <Box
                    component="span"
                    sx={{ display: "inline", width: "10px" }}
                  >
                    <Button
                      key={questionNumber + 1}
                      sx={{
                        marginTop: "10px",
                        backgroundColor: "#607D8B",
                      }}
                      disabled={
                        apiInfo.data.sections[section].questions[questionNumber]
                          .name === "screen" ||
                          apiInfo.data.sections[section].questions[questionNumber]
                            .name === "complete" ||
                          apiInfo.data.sections[section].questions[questionNumber]
                            .name === "overquota"
                          ? true
                          : false
                      }
                      variant="contained"
                      type="submit"
                      value="Back"
                      id="back-button"
                      className="back-Button-form"
                      onClick={() => {
                        changeQuestions(-1);
                      }}
                      display={
                        apiInfo.data.sections[section].questions[questionNumber]
                          .name != "screen"
                          ? "none"
                          : "block"
                      }
                    >
                      Previous
                    </Button>

                    <Button
                      key={questionNumber}
                      sx={{
                        float: "right",
                        marginTop: "10px",
                        backgroundColor: "#607D8B",
                        color: "white",
                      }}
                      id="forward-button"
                      variant="contained"
                      type="submit"
                      value="Next"
                      disabled={disableButton}
                      className="forward-Button-form"
                      onClick={
                        apiInfo.data.sections[section].questions[questionNumber]
                          .name === "screen" ||
                          apiInfo.data.sections[section].questions[questionNumber]
                            .name === "complete" ||
                          apiInfo.data.sections[section].questions[questionNumber]
                            .name === "overquota"
                          ? () => {
                            endQuestions(
                              apiInfo.data.sections[section].questions[
                                questionNumber
                              ].name
                            );
                          }
                          : () => {
                            changeQuestions(1);

                            setTimer(
                              apiInfo.data.sections[section].questions[
                                questionNumber
                              ].id
                            );
                          }
                      }
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
};
export default Form;
